import { FilterField, SearchField } from 'components/fields';
import ManageEntitiesTemplate from 'modules/ManageEntities';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import style from 'assets/styles/manageElements.module.scss';
import {
	MutationFunction,
	QueryFunction,
	UndefinedInitialDataOptions,
	useQueryClient,
} from '@tanstack/react-query';
import { useDebounce } from 'hooks/useDebounce';
import { EntityStatus, TemplateDto } from 'api';
import { authState } from 'store/slices/auth';
import { useAppSelector } from 'hooks/hooks';
import { hasDeletePermission, hasWritePermission } from 'utils';
import adminTemplatesService from 'services/admin/AdminTemplatesService';
import { useParams } from 'react-router-dom';
import ManageEntityCard from 'modules/ManageEntityCard';
import { EditTemplateForm } from 'modules/ManageTemplates/EditTemplateForm';

const ManageTemplates = () => {
	const queryClient = useQueryClient();
	const { permissions } = useAppSelector(authState);
	const canEdit = hasWritePermission(permissions, 'templates');
	const canDelete = hasDeletePermission(permissions, 'templates');
	const [showAddTemplateForm, setShowAddTemplateForm] =
		useState<boolean>(false);
	const [selStatus, setSelStatus] = useState<EntityStatus | undefined>();
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchValue, 700);
	const PAGE_SIZE = 9;
	const queryKeyPrefix: string = 'templates';
	const queryKey: any[] = [
		queryKeyPrefix,
		'infinite',
		debouncedSearchTerm,
		selStatus,
	];
	const queryFn: QueryFunction<any, any[], any> = useCallback(
		({ pageParam: pageNr }) =>
			adminTemplatesService
				.getTemplatesList(pageNr, PAGE_SIZE, debouncedSearchTerm, selStatus)
				.then((res) => res.data),
		[debouncedSearchTerm, selStatus]
	);

	const deleteTemplateItem: MutationFunction<void, { id: number }> =
		useCallback(
			({ id }) =>
				adminTemplatesService
					.deleteTemplateItemById(id)
					.then((res) => res.data),
			[]
		);

	const params = useParams();
	const providedId: number | undefined = (() => {
		const id = params.id;
		if (!id) return undefined;
		const parsedId = parseInt(id, 10);
		return isNaN(parsedId) ? undefined : parsedId;
	})();

	const [openTemplateItemId, setOpenTemplateItemId] = useState<
		number | undefined
	>(providedId);
	const [deletedIds, setDeletedIds] = useState<number[]>([]);

	const fetchSingleTemplateItemOptions: UndefinedInitialDataOptions<
		unknown,
		unknown,
		TemplateDto
	> = {
		queryKey: [queryKeyPrefix, providedId],
		queryFn: () =>
			!!providedId
				? adminTemplatesService
						.getTemplatesItemById(providedId)
						.then((res) => res.data)
				: Promise.reject(),
		enabled: !!providedId && !deletedIds.find((val) => val === providedId),
		initialData: undefined,
	};

	const invalidateTemplatesQuery = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: [queryKeyPrefix] });
		if (!!providedId) {
			setDeletedIds((prev) => [...prev, providedId]);
			queryClient.removeQueries({ queryKey: [queryKeyPrefix, providedId] });
		}
	}, [providedId, queryClient]);

	const renderTemplatesList = useCallback(
		(templates: TemplateDto[]) => (
			<>
				{templates.map((templateItem, index) => (
					<ManageEntityCard
						key={templateItem.id}
						displayMode={{
							type: 'GRID',
							entityStatus: templateItem.entityStatus ?? 'DRAFT',
							imageUrl: templateItem.thumbnailImagePath,
							title: templateItem.title ?? '',
							content: templateItem.description ?? '',
						}}
						deleteFn={() => deleteTemplateItem({ id: templateItem.id })}
						canDelete={canDelete}
						canEdit={canEdit}
						invalidateQueryFn={invalidateTemplatesQuery}
						editForm={
							<EditTemplateForm
								templateInput={templateItem}
								hideForm={() => {
									setOpenTemplateItemId(undefined);
								}}
							/>
						}
						open={openTemplateItemId === templateItem.id}
						setOpen={() => {
							setOpenTemplateItemId(templateItem.id);
						}}
					/>
				))}
			</>
		),
		[
			canDelete,
			canEdit,
			deleteTemplateItem,
			invalidateTemplatesQuery,
			openTemplateItemId,
		]
	);

	const renderItemAddForm = useCallback(
		() => (
			<EditTemplateForm
				templateInput={'new'}
				hideForm={() => {
					setShowAddTemplateForm(false);
				}}
			/>
		),
		[]
	);

	const areFiltersActive = selStatus !== undefined || searchValue !== '';

	const filters: ReactNode = useMemo(
		() => (
			<>
				<div style={{ flex: '1' }} className={style.searchFieldWrapper}>
					<SearchField
						placeholder={'Search'}
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
						}}
					/>
				</div>
				<div style={{ flex: '0 0 10.3125rem' }}>
					<FilterField
						label={'Status: '}
						selValue={selStatus}
						values={[undefined, 'DRAFT', 'PUBLISHED']}
						labels={['All', 'Drafts', 'Published']}
						admin={true}
						setSelectedField={setSelStatus}
					/>
				</div>
			</>
		),
		[searchValue, selStatus]
	);

	return (
		<ManageEntitiesTemplate
			queryKey={queryKey}
			queryFn={queryFn}
			entityName="templates"
			canAddNewItem={canEdit}
			renderItemsList={renderTemplatesList}
			renderItemAddForm={renderItemAddForm}
			filters={filters}
			invalidateEntityQuery={invalidateTemplatesQuery}
			showForm={showAddTemplateForm}
			toggleShowForm={() => {
				setShowAddTemplateForm((prev) => !prev);
			}}
			areFiltersActive={areFiltersActive}
			singleItemQueryOptions={fetchSingleTemplateItemOptions}
			modalAddForm={true}
		/>
	);
};

export default ManageTemplates;
