import React, { useEffect, useMemo, useState } from 'react';

import {
	InputField,
	InputTextarea,
	PictureField,
	UploadFileField,
} from 'components/fields';
import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { SelectAppRelease, SelectField, SelectOpts } from 'components/selects';
import { useAppSelector } from 'hooks/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { editTemplateSchema } from 'validations/FormValidation';
import { AddTemplateItemDto, TemplateDto } from 'api';
import { EntityStatus, EntityStatuses } from 'types/api';
import { TooltipBubble } from 'components/TooltipBubble';
import { App, Modal, Progress } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

interface EditTemplateFormProps {
	hideForm: () => void;
	templateInput: TemplateDto | 'new';
}

interface FormValues {
	editName: string;
	editDescription: string;
	editApplication: number | undefined;
}

export const EditTemplateForm: React.FC<EditTemplateFormProps> = ({
	hideForm,
	templateInput,
}) => {
	const [templateItem] = useState<TemplateDto | 'new'>(templateInput);
	const isNew = templateItem === 'new';

	const { formState, handleSubmit, control, setValue } = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editTemplateSchema),
	});

	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [compressedImage, setCompressedImage] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const [status, setStatus] = useState<EntityStatus>(
		isNew ? 'DRAFT' : templateItem.entityStatus ?? 'DRAFT'
	);
	const [appReleaseId, setAppReleaseId] = useState<number | undefined>(
		isNew ? undefined : templateItem.applicationReleaseId
	);

	const [fileUploadName, setFileUploadName] = useState<string | undefined>(
		isNew ? undefined : templateItem.downloadLink
	);
	const [fileUpload, setFileUpload] = useState<Blob>();
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const { notification } = App.useApp();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'templates');

	const queryClient = useQueryClient();
	const submitFormHandler: SubmitHandler<FormValues> = (data) => {
		isNew ? createTemplateItemHandler(data) : updateTemplateItemHandler(data);
	};

	const { mutate: createTemplateItem, isPending: isPendingCreateTemplate } =
		useMutation({
			mutationFn: ({
				dto,
				image,
				compressedImage,
				attatchment,
				attatchmentPresent,
			}: {
				dto: AddTemplateItemDto;
				image?: Blob;
				compressedImage?: Blob;
				attatchment?: Blob;
				attatchmentPresent?: boolean;
			}) =>
				adminTemplatesService
					.addTemplateItemForm(
						dto,
						image,
						compressedImage,
						attatchment,
						attatchmentPresent,
						{
							onUploadProgress(progressEvent) {
								setUploadProgress(Math.floor(progressEvent.progress! * 100));
							},
						}
					)
					.then((res) => res.data),
			onMutate: () => {
				setUploadProgress(0);
			},
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['templates'] });
				hideForm();
			},
			onError: (e: unknown) => {
				notification.error(
					mcErrorNotification('Error', e, 'create', 'template item')
				);
			},
		});

	const { mutate: updateTemplateItem, isPending: isPendingUpdateTemplate } =
		useMutation({
			mutationFn: ({
				id,
				dto,
				image,
				compressedImage,
				attatchment,
				attatchmentPresent,
				deleteImage,
				deleteTemplateFile,
			}: {
				id: number;
				dto: TemplateDto;
				image?: Blob;
				compressedImage?: Blob;
				attatchment?: Blob;
				attatchmentPresent?: boolean;
				deleteImage?: boolean;
				deleteTemplateFile?: boolean;
			}) =>
				adminTemplatesService
					.updateTemplateItemForm(
						id,
						dto,
						image,
						compressedImage,
						attatchment,
						attatchmentPresent,
						deleteImage,
						deleteTemplateFile,
						{
							onUploadProgress(progressEvent) {
								setUploadProgress(Math.floor(progressEvent.progress! * 100));
							},
						}
					)
					.then((res) => res.data),
			onMutate: () => {
				setUploadProgress(0);
			},
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['templates'] });
				hideForm();
			},
			onError: (e: unknown) => {
				notification.error(
					mcErrorNotification('Error', e, 'update', 'template item')
				);
			},
		});

	const createTemplateItemHandler = (data: FormValues) => {
		if (!appReleaseId) return;
		const dto: AddTemplateItemDto = {
			applicationReleaseId: appReleaseId,
			description: data.editDescription.trim(),
			entityStatus: status,
			title: data.editName.trim(),
		};

		createTemplateItem({
			dto: dto,
			image: selectedImage,
			compressedImage: compressedImage,
			attatchment: fileUpload,
			attatchmentPresent: !!fileUploadName,
		});
	};

	const hasChanges: boolean = useMemo(() => {
		if (isNew) return true;
		const templateFileDeleted = !fileUploadName && !!templateItem.downloadLink;
		const imgChanged = selectedImage instanceof File;
		const attatchmentChanged =
			fileUpload instanceof File &&
			!!fileUploadName &&
			fileUploadName !== templateItem.downloadLink;

		return (
			formState.isDirty ||
			imgChanged ||
			isImageDeleted ||
			attatchmentChanged ||
			templateFileDeleted
		);
	}, [
		fileUpload,
		fileUploadName,
		formState.isDirty,
		isImageDeleted,
		isNew,
		selectedImage,
		templateItem,
	]);

	const updateTemplateItemHandler = (data: FormValues) => {
		if (isNew) return;

		const nameChanged = data.editName.trim() !== templateItem.title;
		const descriptionChanged =
			data.editDescription.trim() !== templateItem.description;
		const statusChanged = status !== templateItem.entityStatus;
		const appReleaseIdChanged =
			appReleaseId !== templateItem.applicationReleaseId;
		const templateFileDeleted = !fileUploadName && !!templateItem.downloadLink;
		const imgChanged = selectedImage instanceof File;
		const attatchmentChanged =
			fileUpload instanceof File &&
			!!fileUploadName &&
			fileUploadName !== templateItem.downloadLink;

		const hasChanged =
			nameChanged ||
			descriptionChanged ||
			statusChanged ||
			appReleaseIdChanged ||
			templateFileDeleted ||
			isImageDeleted ||
			imgChanged ||
			attatchmentChanged;
		if (!hasChanged) return;

		const dto: TemplateDto = {
			id: templateItem.id,
			version: templateItem.version,
			title: nameChanged ? data.editName.trim() : undefined,
			description: descriptionChanged ? data.editDescription.trim() : undefined,
			applicationReleaseId: appReleaseIdChanged ? appReleaseId : undefined,
			entityStatus: statusChanged ? status : undefined,
		};

		updateTemplateItem({
			id: templateItem.id,
			dto: dto,
			image: selectedImage,
			compressedImage: compressedImage,
			attatchment: fileUpload,
			attatchmentPresent:
				!!fileUploadName && fileUploadName !== templateItem.downloadLink,
			deleteImage: isImageDeleted,
			deleteTemplateFile: !fileUploadName && !!templateItem.downloadLink,
		});
	};

	useEffect(() => {
		if (!!templateItem && !isNew) {
			setValue('editName', templateItem.title ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue('editDescription', templateItem.description ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue(
				'editApplication',
				templateItem.applicationReleaseId ?? undefined,
				{
					shouldValidate: Boolean(
						templateItem.applicationReleaseId ?? undefined
					),
					shouldDirty: false,
				}
			);

			setAppReleaseId(templateItem.applicationReleaseId ?? undefined);
			setStatus(templateItem.entityStatus ?? 'DRAFT');
			setFileUploadName(templateItem.downloadLink ?? '');
		}
	}, [isNew, setValue, templateItem]);

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		e.preventDefault();
		hideForm();
	};

	return (
		<>
			<Modal
				open={isPendingCreateTemplate || isPendingUpdateTemplate}
				centered
				maskClosable
				closable={false}
				footer={null}
			>
				<Progress
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					type="circle"
					percent={uploadProgress}
				/>
			</Modal>
			<form
				className={style.addFormWrapper}
				onSubmit={handleSubmit(submitFormHandler)}
			>
				<div className={style.coverImgContainer}>
					<PictureField
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						setCompressedFile={setCompressedImage}
						needToCompress={true}
						existingImagePath={
							isNew ? undefined : templateItem.fullSizeImagePath
						}
						isImageDeleted={isImageDeleted}
						setIsImageDeleted={setIsImageDeleted}
						isAvatar={false}
					/>
					<div className={style.infoTextWrapper}>
						<TooltipBubble
							position="right"
							tooltiptext="Upload an image of proportion W/H 3.05, preferably of horisontal width > 1000 pixels"
						/>
					</div>
				</div>
				<div className={style.uploadImgContainer}>
					<UploadFileField
						text={'Upload template'}
						fileUploadName={fileUploadName}
						setFileUploadName={setFileUploadName}
						setFileUpload={setFileUpload}
						showLoader={false}
					/>
				</div>
				<div className={style.editForm} style={{ marginTop: '1.5rem' }}>
					<div className={style.column}>
						<Controller
							name="editName"
							control={control}
							render={({ field }) => (
								<InputField
									placeholder={'Template name'}
									{...field}
									label={'Template Name'}
									error={!!formState.errors.editName}
									errorMessage={formState.errors.editName?.message}
								/>
							)}
						/>
					</div>
					<div className={style.column}>
						<SelectField
							value={status}
							options={
								[
									{ label: 'Draft', value: EntityStatuses.DRAFT },
									{ label: 'Published', value: EntityStatuses.PUBLISHED },
								] as SelectOpts<EntityStatus | undefined>
							}
							label={'Status'}
							setSelectedField={setStatus}
						/>
					</div>
				</div>
				<Controller
					name="editApplication"
					control={control}
					render={() => (
						<>
							<SelectAppRelease
								appReleaseId={appReleaseId}
								setAppReleaseId={setAppReleaseId}
								label={'Application Release'}
								error={!!formState.errors.editApplication}
								errorMessage={formState.errors.editApplication?.message}
							/>
						</>
					)}
				/>
				<Controller
					name="editDescription"
					control={control}
					render={({ field }) => (
						<InputTextarea
							{...field}
							placeholder={'Description'}
							label={'Description'}
							error={
								formState.errors.editDescription === undefined ? false : true
							}
							errorMessage={formState.errors.editDescription?.message}
							maxCharacterLength={10000}
						/>
					)}
				/>
				{!isNew && (
					<div className={style.buttonsWrapper}>
						<div style={{ color: 'var(--text-disabled)' }}>
							(eid: {templateItem.id}, ver: {templateItem.version})
						</div>
						{templateInput !== 'new' &&
							templateItem.version !== templateInput.version && (
								<div style={{ color: 'var(--add-red)' }}>
									outdated (new existing version:
									{` ${templateInput.version}`})
								</div>
							)}
					</div>
				)}
				<div className={style.buttonsWrapper} style={{ marginTop: '1.5rem' }}>
					<McButton onClick={cancel}>Cancel</McButton>
					<McButton
						primary
						type="submit"
						disabled={
							!canEdit ||
							isPendingCreateTemplate ||
							isPendingUpdateTemplate ||
							!hasChanges
						}
					>
						{isNew ? 'Create' : 'Update'}
					</McButton>
				</div>
			</form>
		</>
	);
};
