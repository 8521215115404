import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { App, Modal, Pagination, Progress, Spin, Tooltip } from 'antd';
import { ImageInfo } from 'api';
import { FileTextIcon, IconTrash } from 'assets/icons/svg';
import style from 'assets/styles/manageElements.module.scss';
import imageStyle from 'assets/styles/manageImages.module.scss';
import { AddButton } from 'components/buttons';
import { SearchField } from 'components/fields';
import { useDebounce } from 'hooks/useDebounce';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import adminImageBankService from 'services/admin/AdminImageBankService';
import {
	deleteItemModal,
	infoNotification,
	mcErrorNotification,
} from 'utils/Notifications';

const ManageImages = () => {
	const queryClient = useQueryClient();
	const [progress, setProgress] = useState(0);
	const { notification, modal } = App.useApp();
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [page, setPage] = useState<number>(1);
	const PAGE_SIZE = 9;

	const {
		data: paginatedResult,
		isLoading: isLoadingImages,
		error: getImagesError,
	} = useQuery({
		queryKey: ['images', 'admin', page, debouncedSearchTerm],
		queryFn: () =>
			adminImageBankService
				.getImageUrls(page, PAGE_SIZE, debouncedSearchTerm)
				.then((res) => res.data),
		placeholderData: keepPreviousData,
		staleTime: 5 * 60 * 1000,
		gcTime: 10 * 60 * 1000,
	});

	const images = paginatedResult ? paginatedResult.images : ([] as ImageInfo[]);
	const totalImages = paginatedResult ? paginatedResult.total : 0;

	useEffect(() => {
		if (page > Math.ceil(totalImages / PAGE_SIZE)) {
			setPage(1);
		}
	}, [page, totalImages]);

	useEffect(() => {
		if (!getImagesError) return;
		notification.error(
			mcErrorNotification('Error', getImagesError, 'fetch', 'images')
		);
	}, [getImagesError, notification]);

	const { mutate: uploadImage, isPending: isPendingUpload } = useMutation({
		mutationFn: (image: Blob) =>
			adminImageBankService
				.uploadImageForm(image, {
					onUploadProgress: (ev) =>
						setProgress(
							!ev.total ? 0 : Math.round((ev.loaded * 100) / ev.total)
						),
				})
				.then((res) => res.data),
		onSuccess: () => {
			notification.success(infoNotification('Image successfully uploaded!'));
			queryClient.invalidateQueries({ queryKey: ['images', 'admin'] });
		},
		onError: (e) =>
			notification.error(mcErrorNotification('Error', e, 'upload', 'image')),
	});

	const { mutate: deleteImage } = useMutation({
		mutationFn: (name: string) =>
			adminImageBankService.deleteImageBankFile(name).then((res) => res.data),
		onSuccess: () => {
			notification.success(infoNotification('Image successfully deleted!'));
			queryClient.invalidateQueries({ queryKey: ['images', 'admin'] });
		},
		onError: (e) =>
			notification.error(mcErrorNotification('Error', e, 'delete', 'image')),
	});

	const uploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files![0];
		fileInputRef.current!.value = '';
		if (!file) return;
		const fileName = file.name?.toLowerCase();

		if (!fileName) return;

		if (!(fileName.endsWith('.jpg') || fileName.endsWith('.png'))) {
			notification.warning(
				infoNotification('Can only upload jpg or png images!')
			);
			return;
		}

		if (file.size > 25 * 1024 * 1024) {
			notification.info(infoNotification('File must be smaller than 25MB'));
			return;
		}

		if (file.name.length >= 25) {
			notification.info(
				infoNotification('File name must be less than 25 characters')
			);
			return;
		}

		uploadImage(file);
	};

	return (
		<>
			<Modal
				open={isPendingUpload}
				centered
				mask
				closable={false}
				footer={null}
				title="Uploading Image..."
			>
				<Progress
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					type="circle"
					percent={progress}
				/>
			</Modal>
			<div className={style.container}>
				<h1 className={style.header}>Manage Images</h1>
				<>
					<div className={style.amountInfo}>
						{`Displaying ${images.length} out of ${totalImages} total images`}
					</div>
					<div className={style.searchAddFields}>
						<div style={{ flex: 'auto' }} className={style.searchFieldWrapper}>
							<SearchField
								placeholder={'Search'}
								value={searchedValue}
								onChange={(e) => {
									setSearchedValue(e.target.value);
								}}
							/>
						</div>
						<>
							<input
								type="file"
								onChange={(ev) => {
									uploadImageHandler(ev);
								}}
								ref={fileInputRef}
							/>
							<AddButton
								disabled={isPendingUpload}
								onClick={() => {
									fileInputRef?.current?.click();
								}}
							/>
						</>
					</div>
				</>
			</div>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}
			>
				<Spin spinning={isLoadingImages}>
					<div className={imageStyle.container}>
						{images.map((imageInfo) => (
							<a
								key={imageInfo.url}
								href={`${imageInfo.url}`}
								target="_blank"
								rel="noreferrer"
							>
								<div className={imageStyle.card}>
									<div className={imageStyle.titleContainer}>
										<Tooltip title={imageInfo.name}>
											<p className={imageStyle.title}>{imageInfo.name}</p>
										</Tooltip>
										<div className={imageStyle.buttonBar}>
											<Tooltip trigger={'click'} title={'name copied!'}>
												<div
													style={{
														width: '20px',
														height: '20px',
													}}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														navigator.clipboard.writeText(imageInfo.name);
													}}
													className={imageStyle.icon}
												>
													<FileTextIcon />
												</div>
											</Tooltip>
											<div
												style={{
													width: '24px',
													height: '24px',
												}}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													const fileName = imageInfo.name;
													modal.confirm(
														deleteItemModal(() => {
															deleteImage(fileName);
														}, fileName)
													);
												}}
												className={`${imageStyle.icon} ${imageStyle.danger}`}
											>
												<IconTrash />
											</div>
										</div>
									</div>
									<img
										src={imageInfo.url}
										alt={imageInfo.name}
										className={imageStyle.image}
									/>
								</div>
							</a>
						))}
					</div>
				</Spin>
				<Pagination
					disabled={isLoadingImages}
					pageSize={PAGE_SIZE}
					total={totalImages}
					onChange={(ev) => {
						setPage(ev);
					}}
					className={imageStyle.pagination}
					defaultPageSize={PAGE_SIZE}
					defaultCurrent={1}
					current={page}
				></Pagination>
			</div>
		</>
	);
};

export default ManageImages;
