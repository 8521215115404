import { isDesktop } from 'desktop';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { open } from '@tauri-apps/api/shell';
import { App } from 'antd';
import { LinkIcon } from 'assets/icons/LinkIcon';

const TemaConnectLink = (
	props: LinkProps & React.RefAttributes<HTMLAnchorElement>
) => {
	const { modal } = App.useApp();

	return (
		<Link
			{...props}
			onClick={(e) => {
				props.onClick?.(e);
				const path = props.to as string;
				if (isDesktop && path.startsWith('/admin')) {
					e.preventDefault();
					modal.confirm({
						centered: true,
						maskClosable: true,
						title: 'Redirect?',
						content: 'This link is only accessible through the browser webapp.',
						okText: (
							<div
								style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}
							>
								Take me there
								<LinkIcon />
							</div>
						),
						onOk: () => {
							open(process.env.REACT_APP_API_URI + path);
						},
					});
				}
			}}
		>
			{props.children}
		</Link>
	);
};

export default TemaConnectLink;
