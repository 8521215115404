import { useQuery } from '@tanstack/react-query';
import { Popover, Empty, Skeleton, App } from 'antd';
import { GlobalSearchDto } from 'api';
import { AlertTriangle, SearchRecentIcon } from 'assets/icons/svg';
import { useDebounce } from 'hooks/useDebounce';
import { supportCategoryByType } from 'modules/Support';
import { FC, useState, useEffect, Dispatch } from 'react';
import { SupportItemType } from 'types/api';
import { capitalize } from 'utils';
import { mcErrorNotification } from 'utils/Notifications';
import utilityService from 'services/UtilityService/UtilityService';
import TemaConnectLink from 'components/TemaConnectLink';

const resultTarget = (result: GlobalSearchDto) => {
	switch (result.type) {
		case 'ENGINE':
			return `/engines/${result.id}`;
		case 'NEWS_ITEM':
			return `/news/${result.id}`;
		case 'APPLICATION':
			return `/apps/${result.id}`;
		case 'APPLICATION_RELEASE':
			return `/apps/${result.id}`;
		case 'TEMPLATE':
			return `/templates/${result.id}`;
		case 'SUPPORT_ITEM':
			return `/support/${result.category?.toLowerCase()}/${result.id}`;
		default:
			return undefined;
	}
};

interface Props extends ChildrenProps {
	query: string;
	fieldFocused?: boolean;
	setSearchBarFocus: Dispatch<boolean>;
}
export const SearchResults: FC<Props> = (props) => {
	const { notification } = App.useApp();
	const query = useDebounce(props.query, 400, { skipWhenClear: true });
	const [fieldFocused, setFieldFocused] = useState<boolean>(false);
	useEffect(() => {
		if (props.fieldFocused === true) {
			setFieldFocused(true);
			return () => {};
		}
		const handler = setTimeout(() => {
			setFieldFocused(props.fieldFocused ?? true);
		}, 200);
		return () => {
			clearTimeout(handler);
		};
	}, [props.fieldFocused]);
	const isVisible = !!query.length && fieldFocused;

	const {
		data: results,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['search', query],
		queryFn: () =>
			utilityService
				.getGlobalSearchResult(query.toLowerCase())
				.then((r) => r.data),
		initialData: [] as GlobalSearchDto[],
		enabled: !!query && query.length > 0,
		gcTime: 0,
		staleTime: 0,
		placeholderData: [] as GlobalSearchDto[],
	});

	useEffect(() => {
		if (!error) return;
		notification.error(
			mcErrorNotification('Error', error, 'complete', 'global search')
		);
	}, [error, notification]);

	return (
		<>
			<Popover
				open={isVisible}
				rootClassName="mc-search"
				overlayClassName="customPopover-topPosition"
				placement={'bottom'}
				arrow={false}
				content={
					<div className="mc-search-container">
						{isLoading ? (
							<>
								<div className="mc-searchResult">
									<Skeleton.Avatar active />
									<Skeleton active paragraph={false} />
								</div>
								<div className="mc-searchResult">
									<Skeleton.Avatar active />
									<Skeleton active paragraph={false} />
								</div>
								<div className="mc-searchResult">
									<Skeleton.Avatar active />
									<Skeleton active paragraph={false} />
								</div>
							</>
						) : results.length ? (
							results.map((result, idx) => (
								<SearchResult
									key={idx}
									result={result}
									setSearchBarFocus={props.setSearchBarFocus}
								/>
							))
						) : query.length ? (
							<Empty
								style={{ padding: '1rem' }}
								description={`No results found for "${query}"...`}
							/>
						) : (
							<></>
						)}
					</div>
				}
				defaultOpen
			>
				{props.children}
			</Popover>
		</>
	);
};

const categoryTitle = (result: GlobalSearchDto) => {
	if (!result.category) return '';
	if (result.type === 'SUPPORT_ITEM') {
		const supportCategory =
			supportCategoryByType[result.category as SupportItemType];
		if (supportCategory) {
			return supportCategory.label;
		}
	}

	return capitalize(result.category);
};

const SearchResult: FC<{
	result: GlobalSearchDto;
	setSearchBarFocus: Dispatch<boolean>;
}> = ({ result, setSearchBarFocus }) => {
	const target = resultTarget(result);
	const content = (
		<>
			<div>{result.title}</div>
			<div className="mc-x-category">{categoryTitle(result)}</div>
		</>
	);
	return target ? (
		<TemaConnectLink
			to={target}
			className="mc-searchResult"
			onMouseDown={(e) => e.preventDefault()}
			onMouseUp={(e) => setSearchBarFocus(false)}
		>
			<SearchRecentIcon />

			{content}
		</TemaConnectLink>
	) : (
		<div className="mc-searchResult">
			<div
				style={{ height: '1.5rem', color: 'var(--add-red)' }}
				title={`Unknown result type: ${capitalize(result.type)}`}
			>
				<AlertTriangle />
			</div>
			{content}
		</div>
	);
};
