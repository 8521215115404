import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import style from 'assets/styles/supportPage.module.scss';
import SupportSidebar from 'modules/Support/SupportSidebar';
import { App, Collapse, Layout, Space, Spin, Tooltip } from 'antd';
import { SearchField } from 'components/fields';
import { useNavigate, useParams } from 'react-router-dom';
import { IconLink } from 'assets/icons/svg';
import { SupportItemTypes } from 'types/api';
import { useDebounce } from 'hooks/useDebounce';
import {
	SupportCategory,
	SupportPanelContent,
	supportCategoryByKey,
} from 'modules/Support';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import supportService from 'services/SupportService';
import { SupportItemDto } from 'api';
import tagService from 'services/TagsService';
import { mcErrorNotification } from 'utils/Notifications';
import { McButton } from 'components/mc';
import ReleaseNotes from './ReleaseNotes';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasReadPermission } from 'utils/permissions';
import { NotFound } from './NotFound';
import { isDesktop } from 'desktop';
import TemaConnectLink from 'components/TemaConnectLink';
import McSpinner from 'components/MotionCloudSpinner';

const { Content } = Layout;

const contentStyle: React.CSSProperties = {
	paddingLeft: '2rem',
};

const CustomPlusIcon: FC<{ rotate: number }> = ({ rotate }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path
			d="M6 9L12 15L18 9"
			stroke="#89969F"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const SearchAndTitle: FC<{
	searchedValueText: string;
	setSearchedValueText: React.Dispatch<React.SetStateAction<string>>;
	supportItemsLength: number;
	categoryInfo?: { items: string };
	supportItemsCount: number;
}> = ({
	searchedValueText,
	setSearchedValueText,
	supportItemsLength,
	categoryInfo,
	supportItemsCount,
}) => (
	<>
		<h3 className={style.subtitle}>
			{`Displaying ${supportItemsLength} ${categoryInfo?.items} out of ${supportItemsCount}`}
		</h3>
		<div className={style['search-container']}>
			<div className={style['search-field-wrapper']}>
				<SearchField
					placeholder="Search"
					value={searchedValueText}
					onChange={(e) => setSearchedValueText(e.target.value)}
				/>
			</div>
		</div>
	</>
);

const SupportItemsCollapse: FC<{
	supportItems: SupportItemDto[];
	tags: any[];
	activePanel: string[];
	handlePanelChange: (key: string | string[]) => void;
	handleCopyLink: (itemId: number) => void;
	copiedId: string | null;
	panelStyle: CSSProperties;
	firstChildPanelStyle: CSSProperties;
	category: SupportCategory;
}> = ({
	supportItems,
	tags,
	activePanel,
	handlePanelChange,
	handleCopyLink,
	copiedId,
	panelStyle,
	firstChildPanelStyle,
	category,
}) => (
	<Spin spinning={false}>
		<Collapse
			bordered={false}
			activeKey={activePanel}
			onChange={handlePanelChange}
			className={style['collapse-container']}
			expandIcon={({ isActive }) => (
				<CustomPlusIcon rotate={isActive ? 180 : 0} />
			)}
			expandIconPosition="end"
			items={supportItems.map((item, index) => ({
				key: item.id,
				style: index === 0 ? firstChildPanelStyle : panelStyle,
				label: (
					<div className={style['faq-text']}>
						<div className="flex between w-full">
							{item.question}
							{!isDesktop && ( // show link only on web
								<Tooltip
									title={
										copiedId === String(item.id)
											? 'Copied to clipboard!'
											: 'Click to copy link'
									}
								>
									<TemaConnectLink
										to={`/support/${category}/${item.id}`}
										onClick={(e) => {
											e.preventDefault();
											handleCopyLink(item.id);
										}}
										className={style['faq-link']}
									>
										<IconLink size="1rem" />
									</TemaConnectLink>
								</Tooltip>
							)}
						</div>
						<div className="faq-date"></div>
					</div>
				),
				children: <SupportPanelContent allTags={tags} supportItem={item} />,
			}))}
		/>
	</Spin>
);

export const SupportPage: FC = () => {
	const navigate = useNavigate();
	const params = useParams();
	const [activePanel, setActivePanel] = useState<string[]>(
		params.id ? [params.id] : []
	);
	const category: SupportCategory =
		(params.category as SupportCategory) ?? 'qa';

	const { isAdmin, permissions } = useAppSelector(authState);

	const canReadInternal = hasReadPermission(permissions, 'internal');

	const isAdminNotes = category === 'admin';
	const isReleaseNotes = category === ('release-notes' as SupportCategory);
	const categoryInfo = isReleaseNotes
		? undefined
		: supportCategoryByKey[category];
	const supportItemType = categoryInfo?.itemType ?? SupportItemTypes.QA;
	const PAGE_SIZE = 10;

	const [searchedValueText, setSearchedValueText] = useState('');
	const debouncedSearchText = useDebounce(searchedValueText, 700);
	const { notification } = App.useApp();

	const [copiedId, setCopiedId] = useState<string | null>(null);

	const handleCopyLink = (itemId: number) => {
		const url = `${window.location.origin}/support/${category}/${itemId}`;
		navigator.clipboard.writeText(url);
		setCopiedId(String(itemId));
		setTimeout(() => setCopiedId(null), 2000);
	};

	const {
		data: supportItemPages,
		error: getSupportItemsError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: [
			'supportItems',
			'infinite',
			debouncedSearchText,
			supportItemType,
		],
		queryFn: ({ pageParam: pageNr }) =>
			supportService
				.supportItemsList(
					pageNr,
					PAGE_SIZE,
					debouncedSearchText,
					supportItemType
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const supportItems = useMemo(
		() =>
			!!supportItemPages
				? supportItemPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as SupportItemDto[]
				  )
				: [],
		[supportItemPages]
	);

	const supportItemsCount =
		!!supportItemPages && supportItemPages.pages.length > 0
			? supportItemPages.pages[0].allElements
			: 0;

	const { data: tags, error: getTagsError } = useQuery({
		queryKey: ['tags'],
		queryFn: () => tagService.getTagsList().then((res) => res.data),
		initialData: [],
	});

	useEffect(() => {
		if (!getTagsError) return;
		notification.warning(
			mcErrorNotification('Warning', getTagsError, 'fetch', 'tags')
		);
	}, [getTagsError, notification]);

	useEffect(() => {
		if (!getSupportItemsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getSupportItemsError,
				'fetch',
				'support items'
			)
		);
	}, [getSupportItemsError, notification]);

	const providedId: number | undefined = (() => {
		const id = params.id;
		if (!id) return undefined;
		const parsedId = parseInt(id, 10);
		return isNaN(parsedId) ? undefined : parsedId;
	})();

	const {
		data: singleSupportItem,
		error: fetchSingleSupportItemError,
		isLoading: isLoadingSingleSupportItem,
	} = useQuery({
		queryKey: ['supportItems', providedId],
		queryFn: () =>
			supportService
				.getSupportItemById(providedId ?? -1)
				.then((res) => res.data),
		enabled: !!providedId,
		initialData: undefined,
	});

	useEffect(() => {
		if (!fetchSingleSupportItemError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				fetchSingleSupportItemError,
				'fetch',
				'specified support item'
			)
		);
	}, [fetchSingleSupportItemError, notification]);

	const panelStyle: CSSProperties = {
		marginTop: 8,
		background: 'var(--bg)',
		border: 'none',
	};

	const firstChildPanelStyle: CSSProperties = {
		...panelStyle,
		marginTop: 0,
	};

	const handlePanelChange = (key: string | string[]) => {
		setActivePanel(Array.isArray(key) ? key : [key]);
	};

	const viewMoreButton = (
		<div
			className={style['view-more-container']}
			onClick={() => fetchNextPage()}
		>
			<McButton disabled={isLoading || isFetching}>View more</McButton>
		</div>
	);

	const showSingleItem: boolean = !!providedId && !!singleSupportItem;

	if (isAdminNotes && !(isAdmin || canReadInternal)) return <NotFound />;

	return (
		<Space direction="vertical" className={style['page-layout']} size={[0, 48]}>
			<Layout className={style['transparent-layout']}>
				<Layout hasSider className={style['transparent-layout']}>
					<SupportSidebar selected={category} />
					<Content style={contentStyle}>
						{isReleaseNotes ? (
							<ReleaseNotes />
						) : (
							<>
								{!showSingleItem && !isLoadingSingleSupportItem && (
									<SearchAndTitle
										searchedValueText={searchedValueText}
										setSearchedValueText={setSearchedValueText}
										supportItemsLength={supportItems.length}
										categoryInfo={categoryInfo}
										supportItemsCount={supportItemsCount}
									/>
								)}
								{isLoading || isLoadingSingleSupportItem ? (
									<div
										style={{
											justifySelf: 'center',
										}}
									>
										<McSpinner />
									</div>
								) : (
									<SupportItemsCollapse
										supportItems={
											showSingleItem && !!singleSupportItem
												? [singleSupportItem]
												: supportItems
										}
										tags={tags}
										activePanel={activePanel}
										handlePanelChange={handlePanelChange}
										handleCopyLink={handleCopyLink}
										copiedId={copiedId}
										panelStyle={panelStyle}
										firstChildPanelStyle={firstChildPanelStyle}
										category={category}
									/>
								)}
								{showSingleItem ? (
									<McButton
										disabled={isLoadingSingleSupportItem}
										onClick={() => {
											navigate('/support');
										}}
										style={{ marginTop: '2rem', justifySelf: 'center' }}
									>
										Browse Other
									</McButton>
								) : (
									hasNextPage && viewMoreButton
								)}
							</>
						)}
					</Content>
				</Layout>
			</Layout>
		</Space>
	);
};
