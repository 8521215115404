import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import oneUserReducer from './slices/oneUser';
import allAppReleasesShortInfoReducer from './slices/allAppReleasesShortInfo';
import modulesReducer from './slices/modules';
import supportsReducer from './slices/supports';
import tagsReducer from './slices/tags';
import tourReducer from './slices/tour';
import codemeterReducer from './slices/Codemeter';
import updaterReducer from './slices/updater';

const reducers = combineReducers({
	auth: authReducer,
	oneUser: oneUserReducer,
	allAppReleasesShortInfo: allAppReleasesShortInfoReducer,
	modules: modulesReducer,
	supports: supportsReducer,
	tags: tagsReducer,
	tour: tourReducer,
	codemeter: codemeterReducer,
	updater: updaterReducer,
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
