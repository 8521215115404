/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { PaginatedImages } from '../model';
/**
 * AdminImagesApi - axios parameter creator
 * @export
 */
export const AdminImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete image bank blob
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageBankFile: async (imageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageName' is not null or undefined
            if (imageName === null || imageName === undefined) {
                throw new RequiredError('imageName','Required parameter imageName was null or undefined when calling deleteImageBankFile.');
            }
            const localVarPath = `/api/admin/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (imageName !== undefined) {
                localVarQueryParameter['imageName'] = imageName;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets paginated image urls
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageUrls: async (page: number, size: number, searchedValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getImageUrls.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getImageUrls.');
            }
            const localVarPath = `/api/admin/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an image to the image bank
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageForm: async (image?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (image !== undefined) {
                const imageValue = typeof image === 'object' && !(image instanceof Blob) ? JSON.stringify(image) : image;
                localVarFormParams.append('image', imageValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminImagesApi - functional programming interface
 * @export
 */
export const AdminImagesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete image bank blob
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageBankFile(imageName: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminImagesApiAxiosParamCreator(configuration).deleteImageBankFile(imageName, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Gets paginated image urls
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageUrls(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PaginatedImages>> {
            const localVarAxiosArgs = await AdminImagesApiAxiosParamCreator(configuration).getImageUrls(page, size, searchedValue, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Upload an image to the image bank
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(image?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminImagesApiAxiosParamCreator(configuration).uploadImageForm(image, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminImagesApi - factory interface
 * @export
 */
export const AdminImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete image bank blob
         * @param {string} imageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageBankFile(imageName: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminImagesApiFp(configuration).deleteImageBankFile(imageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets paginated image urls
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageUrls(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PaginatedImages>> {
            return AdminImagesApiFp(configuration).getImageUrls(page, size, searchedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an image to the image bank
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(image?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminImagesApiFp(configuration).uploadImageForm(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminImagesApi - object-oriented interface
 * @export
 * @class AdminImagesApi
 * @extends {BaseAPI}
 */
export class AdminImagesApi extends BaseAPI {
    /**
     * 
     * @summary Delete image bank blob
     * @param {string} imageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminImagesApi
     */
    public async deleteImageBankFile(imageName: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminImagesApiFp(this.configuration).deleteImageBankFile(imageName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets paginated image urls
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminImagesApi
     */
    public async getImageUrls(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PaginatedImages>> {
        return AdminImagesApiFp(this.configuration).getImageUrls(page, size, searchedValue, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Upload an image to the image bank
     * @param {Blob} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminImagesApi
     */
    public async uploadImageForm(image?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminImagesApiFp(this.configuration).uploadImageForm(image, options).then((request) => request(this.axios, this.basePath));
    }
}
