import { useQuery } from '@tanstack/react-query';
import { SubscriptionDto } from 'api';
import { CheckIcon, SupportIcon2 } from 'assets/icons/svg';
import { McButton } from 'components/mc';
import { CmContainer, ipc, isDesktop } from 'desktop';
import { Dispatch, useEffect } from 'react';
import pageStyle from './ActivationWizardPages.module.scss';
import selectContainerPageStyle from './SelectContainerPage.module.scss';
import { App, Spin, Tooltip } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';
import { ActivationWizardState } from '../ActivationWizard';
import { authState } from 'store/slices/auth';
import { useAppSelector } from 'hooks/hooks';
import { getTimeRemainingSuffix } from 'utils/date';

interface Props {
	subscription?: SubscriptionDto;
	setDisplayActivationWizard: Dispatch<boolean>;
	selectedContainer: CmContainer | undefined;
	setSelectedContainer: Dispatch<CmContainer | undefined>;
	changeDisplayPage: Dispatch<ActivationWizardState>;
	preSelectedContainerId?: string;
}

const SelectContainerPage = ({
	subscription,
	setDisplayActivationWizard,
	selectedContainer,
	setSelectedContainer,
	changeDisplayPage,
	preSelectedContainerId,
}: Props) => {
	const { notification } = App.useApp();
	const { isGuest } = useAppSelector(authState);

	const {
		data: containers,
		isLoading: isLoadingContainers,
		error: getContainersError,
	} = useQuery({
		queryKey: ['codemeter', 'containers'],
		queryFn: () =>
			Promise.allSettled([
				ipc.getCodemeterContainers(6000157, 'Act'), // Might not need product code for this function?
				ipc.getCodemeterContainers(6000157, 'Dongle'),
			]).then((res) => {
				const actContainers =
					res[0].status === 'fulfilled' ? res[0].value : ([] as CmContainer[]);
				const dongleContainers =
					res[1].status === 'fulfilled' ? res[1].value : ([] as CmContainer[]);
				return [...actContainers, ...dongleContainers];
			}),
		initialData: [] as CmContainer[],
		enabled: isDesktop,
	});

	useEffect(() => {
		const searchResult: CmContainer | undefined = containers.find(
			(container) => container.identifier === preSelectedContainerId
		);
		if (!!searchResult) setSelectedContainer(searchResult);
	}, [containers, preSelectedContainerId, setSelectedContainer]);

	useEffect(() => {
		if (!getContainersError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getContainersError,
				'fetch',
				'codemeter containers'
			)
		);
	}, [getContainersError, notification]);

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Select Container</div>
				{!isGuest && !!subscription && !!subscription.renewalDate && (
					<div
						style={{
							marginTop: '12px',
						}}
						className={pageStyle.pageSubTitle}
					>
						Subscription renewal:{' '}
						{`${subscription.renewalDate} ${getTimeRemainingSuffix(
							new Date(subscription.renewalDate),
							true
						)}`}
					</div>
				)}
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					Licenses to run TEMA applications are installed and activated in
					containers. Containers that are reachable on this computer should be
					activated from the Motion Cloud Desktop Application instead. It will
					automatically detect any containers and guide you through the
					activation process.
					<br></br>If you intend to activate a license in a container on another
					computer you can press the button "Offline Activation" to proceed.
					<br /> <br />* The number of activated containers are limited to 5,
					but more can be purchased.
				</div>
				{isLoadingContainers ? (
					<Spin
						style={{ margin: '0 auto', width: '100%' }}
						spinning={isLoadingContainers}
					/>
				) : containers.length === 0 ? (
					<div
						style={{ width: '100%', textAlign: 'center', marginBottom: '24px' }}
					>
						No containers were found!
					</div>
				) : (
					<div
						className={selectContainerPageStyle.container}
						style={{ marginBottom: '24px' }}
					>
						<div
							className={`${selectContainerPageStyle.listItem} ${selectContainerPageStyle.headerItem}`}
						>
							<div style={{ width: 'auto', padding: '8px 16px' }}>
								Container
							</div>
							<div
								style={{
									width: '274px',
									padding: '8px 16px',
								}}
							>
								Type
							</div>
						</div>
						<div className={selectContainerPageStyle.itemContainer}>
							{containers.map((container) => (
								<div
									className={`${selectContainerPageStyle.listItem} ${
										selectedContainer?.identifier === container.identifier
											? selectContainerPageStyle.selectedListItem
											: ''
									}`}
									onClick={() => {
										if (!!preSelectedContainerId && !!container) return;
										setSelectedContainer(
											container.identifier === selectedContainer?.identifier
												? undefined
												: container
										);
									}}
									id={container.identifier}
								>
									<div
										className={selectContainerPageStyle.firstElement}
									>{`Tema ${
										container.container_type === 'Dongle' ? 'Dongle' : ''
									} (${container.identifier})`}</div>
									<div className={selectContainerPageStyle.secondElement}>
										{container.container_type === 'Act'
											? 'Software'
											: container.container_type}
										{selectedContainer?.identifier === container.identifier && (
											<CheckIcon />
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<div
					style={{
						alignItems: 'end',
						display: 'inline-flex',
					}}
				>
					<McButton onClick={() => setDisplayActivationWizard(false)}>
						Cancel
					</McButton>
				</div>
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '16px',
							alignItems: 'center',
						}}
					>
						<McButton
							style={{ marginRight: '12px' }}
							onClick={() => {
								changeDisplayPage('OfflineActivation');
							}}
						>
							Offline Activation
							{/* FIXME: change the activation to activation/extension/deactivation */}
						</McButton>
						<Tooltip
							title={
								'In situations where you have an offline computer that requires a license and an online computer which can be used as an intermediary!'
							}
						>
							<div className={pageStyle.supportIcon}>
								<SupportIcon2 />
							</div>
						</Tooltip>
					</div>
					<McButton
						primary
						style={{ height: '38px' }}
						disabled={selectedContainer === undefined || !!isGuest}
						onClick={() => {
							changeDisplayPage('DefineActivation');
						}}
					>
						Use selected container
					</McButton>
				</div>
			</div>
		</div>
	);
};

export default SelectContainerPage;
