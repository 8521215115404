import {
	MutationFunction,
	QueryFunction,
	UndefinedInitialDataOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { EntityStatus, NewsCategory, NewsDto } from 'api';
import { FilterField, SearchField } from 'components/fields';
import { useAppSelector } from 'hooks/hooks';
import { useDebounce } from 'hooks/useDebounce';
import ManageEntitiesTemplate from 'modules/ManageEntities';
import { EditNewsForm } from 'modules/ManageNews/EditNewsForm';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import newsService from 'services/NewsService';
import { authState } from 'store/slices/auth';
import { hasDeletePermission, hasWritePermission } from 'utils';
import style from 'assets/styles/manageElements.module.scss';
import ManageEntityCard from 'modules/ManageEntityCard';
import { McIconButton } from 'components/mc';
import { StarIcon } from 'assets/icons/svg';
import { App, Tooltip } from 'antd';
import { NewsStatuses } from 'types/api';
import { infoNotification, mcErrorNotification } from 'utils/Notifications';
import adminNewsService from 'services/admin/AdminNewsService';
import { useParams } from 'react-router-dom';

const ManageNews = () => {
	const queryClient = useQueryClient();
	const { notification } = App.useApp();
	const { permissions } = useAppSelector(authState);
	const canEdit = hasWritePermission(permissions, 'news');
	const canDelete = hasDeletePermission(permissions, 'news');
	const [showAddNewsForm, setShowAddNewsForm] = useState<boolean>(false);
	const [selStatus, setSelStatus] = useState<EntityStatus | undefined>();
	const [selCategory, setSelCategory] = useState<NewsCategory | undefined>();
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchValue, 700);
	const PAGE_SIZE = 9;
	const queryKeyPrefix: string = 'news';
	const queryKey: any[] = [
		queryKeyPrefix,
		'infinite',
		debouncedSearchTerm,
		selStatus,
		selCategory,
	];
	const queryFn: QueryFunction<any, any[], any> = useCallback(
		({ pageParam: pageNr }) =>
			newsService
				.getNewsList(
					pageNr,
					PAGE_SIZE,
					debouncedSearchTerm,
					selStatus,
					selCategory
				)
				.then((res) => res.data),
		[debouncedSearchTerm, selCategory, selStatus]
	);

	const deleteNewsItem: MutationFunction<void, { id: number }> = useCallback(
		({ id }) => adminNewsService.deleteNewsItemById(id).then((res) => res.data),
		[]
	);

	const { mutate: setAsFeatured } = useMutation({
		mutationFn: (newsId: number) =>
			adminNewsService.markNewsItemAsFeatured(newsId).then((res) => res.data),
		onSuccess: () => {
			notification.success(
				infoNotification('Successfully marked the news item as featured!')
			);
			invalidateNewsQuery();
		},
		onError: (e: any) => {
			notification.error(
				mcErrorNotification('Error', e, 'mark', 'news item as featured')
			);
		},
	});

	const params = useParams();
	const providedId: number | undefined = (() => {
		const id = params.id;
		if (!id) return undefined;
		const parsedId = parseInt(id, 10);
		return isNaN(parsedId) ? undefined : parsedId;
	})();

	const [openNewsItemId, setOpenNewsItemId] = useState<number | undefined>(
		providedId
	);
	const [deletedIds, setDeletedIds] = useState<number[]>([]);

	const fetchSingleNewsItemOptions: UndefinedInitialDataOptions<
		unknown,
		unknown,
		NewsDto
	> = {
		queryKey: [queryKeyPrefix, providedId],
		queryFn: () =>
			!!providedId
				? newsService.getNewsItemById(providedId).then((res) => res.data)
				: Promise.reject(),
		enabled: !!providedId && !deletedIds.find((val) => val === providedId),
		initialData: undefined,
	};

	const invalidateNewsQuery = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: [queryKeyPrefix] });
		if (!!providedId) {
			setDeletedIds((prev) => [...prev, providedId]);
			queryClient.removeQueries({ queryKey: [queryKeyPrefix, providedId] });
		}
	}, [providedId, queryClient]);

	const renderNewsList = useCallback(
		(news: NewsDto[]) => (
			<>
				{news.map((newsItem, index) => (
					<ManageEntityCard
						key={newsItem.id}
						displayMode={{
							type: 'GRID',
							entityStatus: newsItem.entityStatus ?? 'DRAFT',
							imageUrl: newsItem.thumbnailImagePath,
							title: newsItem.title ?? '',
							content: newsItem.description ?? '',
						}}
						buttons={
							<>
								<Tooltip title={'Set as featured'}>
									<div
										className={`${
											newsItem.extraNew &&
											newsItem.entityStatus === NewsStatuses.PUBLISHED
												? style.extraNewsIcon
												: style.starIcon
										}`}
										style={{ display: 'inline-flex' }}
									>
										<McIconButton
											icon={<StarIcon />}
											disabled={!canEdit}
											onClick={() => {
												setAsFeatured(newsItem.id);
											}}
										/>
									</div>
								</Tooltip>
							</>
						}
						deleteFn={() => deleteNewsItem({ id: newsItem.id })}
						canDelete={canDelete}
						canEdit={canEdit}
						invalidateQueryFn={invalidateNewsQuery}
						editForm={
							<EditNewsForm
								newsItemInput={newsItem}
								hideForm={() => {
									setOpenNewsItemId(undefined);
								}}
							/>
						}
						open={openNewsItemId === newsItem.id}
						setOpen={() => {
							setOpenNewsItemId(newsItem.id);
						}}
						date={newsItem.date}
					/>
				))}
			</>
		),
		[
			canDelete,
			canEdit,
			deleteNewsItem,
			invalidateNewsQuery,
			openNewsItemId,
			setAsFeatured,
		]
	);

	const renderItemAddForm = useCallback(
		() => (
			<EditNewsForm
				newsItemInput={'new'}
				hideForm={() => {
					setShowAddNewsForm(false);
				}}
			/>
		),
		[]
	);

	const areFiltersActive =
		selStatus !== undefined || selCategory !== undefined || searchValue !== '';

	const filters: ReactNode = useMemo(
		() => (
			<>
				<div style={{ flex: '1' }} className={style.searchFieldWrapper}>
					<SearchField
						placeholder={'Search'}
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
						}}
					/>
				</div>
				<div style={{ flex: '0 0 10.3125rem' }}>
					<FilterField
						label={'Category: '}
						selValue={selCategory}
						values={[undefined, 'NEWS', 'ADMIN_NOTICE', 'SOFTWARE_RELEASE']}
						labels={['All', 'News', 'Admin notice', 'Software release']}
						admin={true}
						setSelectedField={setSelCategory}
					/>
				</div>
				<div style={{ flex: '0 0 10.3125rem' }}>
					<FilterField
						label={'Status: '}
						selValue={selStatus}
						values={[undefined, 'DRAFT', 'PUBLISHED']}
						labels={['All', 'Drafts', 'Published']}
						admin={true}
						setSelectedField={setSelStatus}
					/>
				</div>
			</>
		),
		[searchValue, selCategory, selStatus]
	);

	return (
		<ManageEntitiesTemplate
			queryKey={queryKey}
			queryFn={queryFn}
			entityName="news"
			canAddNewItem={canEdit}
			renderItemsList={renderNewsList}
			renderItemAddForm={renderItemAddForm}
			filters={filters}
			invalidateEntityQuery={invalidateNewsQuery}
			showForm={showAddNewsForm}
			toggleShowForm={() => {
				setShowAddNewsForm((prev) => !prev);
			}}
			areFiltersActive={areFiltersActive}
			singleItemQueryOptions={fetchSingleNewsItemOptions}
			modalAddForm={true}
		/>
	);
};

export default ManageNews;
