import React, { CSSProperties, forwardRef, useState } from 'react';
import style from 'assets/styles/components.module.scss';

interface InputProps {
	placeholder: string;
	value: string;
	label?: string;
	tabIndex?: number;
	height?: string;
	error?: boolean;
	errorMessage?: string;
	onFocus?: () => void;
	onBlur?: () => void;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	maxCharacterLength?: number;
}
export type InputTextAreaRef = HTMLTextAreaElement;

export const InputTextarea: React.FC<InputProps> = forwardRef<
	InputTextAreaRef,
	InputProps
>((props, ref) => {
	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		props.onChange(e);
	};

	const [focus, setFocus] = useState(false);
	const textLength = (props.value ?? '').length;

	const characterCountStyle: CSSProperties = {
		color:
			!!props.maxCharacterLength && textLength > props.maxCharacterLength
				? 'var(--add-red)'
				: 'var(--text-disabled)',
		fontWeight: '400',
		fontSize: '0.7rem',
	};

	const onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		setTimeout(() => {
			setFocus(false);
		}, 100);
	};

	const onFocus = () => {
		props.onFocus?.();
		setTimeout(() => {
			setFocus(true);
		}, 100);
	};

	return (
		<>
			<div className={style.inputFieldWrapper}>
				<span className={style.inputFieldLabel}>
					{props.label}
					{!!props.maxCharacterLength && (
						<span style={characterCountStyle}>
							{' '}
							({textLength}/{props.maxCharacterLength})
						</span>
					)}
				</span>
				<div
					className={style.inputTextareaWrapper}
					style={{ border: props.error ? '1.5px solid var(--add-red)' : '' }}
				>
					<textarea
						className={`${style.inputTextarea} ${
							focus ? style.expandInputTextArea : ''
						}`}
						style={{ height: props.height }}
						placeholder={props.placeholder}
						ref={ref}
						value={props.value}
						tabIndex={props.tabIndex}
						onChange={onChangeHandler}
						onFocus={onFocus}
						onBlur={onBlur}
					></textarea>
				</div>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
