import { Switch } from 'antd';
import {
	SunLightModeIcon,
	MoonDarkModeIcon,
	ContactIcon,
	ShowTourIcon,
	// AboutIcon,
} from 'assets/icons/svg';
import {
	IconEdit,
	AvatarIcon,
	LogOutIcon,
	DownloadIcon,
	LinkIcon,
} from 'assets/icons/svg';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { authState, logout } from 'store/slices/auth';
import { ProfileSettingsPopOver } from './ProfileSettingsPopOver';

import 'assets/styles/ProfilePopOver.scss';
import { ipc, isDesktop } from 'desktop';
import { downloadDesktopAppUrl, getPublicImageURL } from 'services/ApiService';
import { useLightMode } from 'hooks/useLightMode';
import { ClearOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
// import { AboutPopoverContent } from './AboutPopoverContent';
import { ContactPopoverContent } from './ContactPopoverContent';
import { Cond } from 'utils/Cond';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalSettings } from 'settings';
import { setTourOpen } from 'store/slices/tour';
import { isUserDto } from 'types/user';

type AddonType = 'CONTACT' | 'EDIT' | /* 'ABOUT' | */ 'PREFS';

export const ProfilePopOver = ({
	setVisible,
	visible,
}: {
	setVisible: (value: boolean) => void;
	visible: boolean;
}) => {
	const { user, isGuest } = useAppSelector(authState);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isLightMode, setIsLightMode] = useLightMode();
	const { logout: authLogout } = useAuth0();
	const [, setTourDismissed] = useLocalSettings('dismissIntroductionTour');

	const [addonContent, setAddonContent] = useState<AddonType>();

	const logoutHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!isGuest) {
			authLogout({
				logoutParams: {
					returnTo: window.location.origin,
				},
			});
		} else {
			dispatch(logout({ isGuest }));
			navigate('/login', { replace: true });
		}
	};

	const handleLightModeToggle = () => {
		setIsLightMode(!isLightMode);
	};

	const toggleAddonContent = (content: AddonType) =>
		setAddonContent((c) => (c === content ? undefined : content));
	const closeAddonContent = () => setAddonContent(undefined);

	useEffect(() => {
		setAddonContent(undefined);
	}, [visible]);

	return (
		<>
			<Cond if={addonContent}>
				<div className="profile-addon ant-popover-inner">
					{addonContent === 'CONTACT' ? (
						<ContactPopoverContent setVisible={closeAddonContent} />
					) : addonContent === 'EDIT' ? (
						<ProfileSettingsPopOver
							setVisible={closeAddonContent}
							visible={true}
						/> /* : addonContent === 'ABOUT' ? (
						<AboutPopoverContent
							setVisible={() => toggleAddonContent('CONTACT')}
						/>
					) */
					) : (
						<></>
					)}
				</div>
			</Cond>
			<div className="popover-content">
				{isUserDto(user) && (
					<div className="profile-section">
						<div className="profile-avatarContainer">
							<div className="profile-avatar">
								{!user.avatarPath ? (
									<AvatarIcon />
								) : (
									<img alt="" src={getPublicImageURL(user.avatarPath)} />
								)}
							</div>
							<div
								className="edit-button"
								onClick={() => toggleAddonContent('EDIT')}
							>
								<IconEdit />
							</div>
						</div>
						<div className="profile-infoContainer">
							<p className="profile-name">
								{user.firstName} {user.lastName}
							</p>
							<p className="profile-email">{user.email}</p>
						</div>
					</div>
				)}
				<div className="middle-section">
					<a
						className={`link-desktop ${!isDesktop ? 'web-border' : ''}`}
						download
						href={downloadDesktopAppUrl}
					>
						<DownloadIcon />
						Download Desktop App
					</a>

					{/* <Cond if={isDesktop}>
						<div
							className="about-button"
							onClick={() => toggleAddonContent('ABOUT')}
						>
							<AboutIcon />
							<p>About</p>
						</div>
					</Cond> */}
					<Cond if={isDesktop}>
						<div
							className="link-desktop"
							onClick={() => ipc.launchBrowser(process.env.REACT_APP_API_URI)}
						>
							<LinkIcon />
							Go to Web App
						</div>
					</Cond>
					<div
						className="contact-button"
						onClick={() => toggleAddonContent('CONTACT')}
					>
						<ContactIcon />
						<p>Contact us</p>
					</div>

					<div
						className="contact-button"
						onClick={() => {
							setTourDismissed(false);
							dispatch(setTourOpen(true));
							navigate('/');
						}}
					>
						<ShowTourIcon />
						<p>Show Tour Again</p>
					</div>
				</div>
				<div className="down-section">
					<div className="log-out" onClick={logoutHandler}>
						<LogOutIcon /> <p>{isGuest ? 'Log In' : 'Log Out'}</p>
					</div>
					<div className="light-dark-toggle">
						<div
							title="Clear color scheme preference"
							onClick={() => setIsLightMode(null)}
							style={{
								opacity: '0.2',
								display: 'inline-block',
								marginRight: '.5rem',
								cursor: 'pointer',
							}}
						>
							<ClearOutlined />
						</div>
						<Switch
							checked={isLightMode}
							onChange={handleLightModeToggle}
							checkedChildren={<SunLightModeIcon />}
							unCheckedChildren={<MoonDarkModeIcon />}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
