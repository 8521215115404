import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { DisabledScreen } from 'components/DisabledScreen';
import {
	InputField,
	InputTextarea,
	PictureField,
	UploadFileField,
} from 'components/fields';
import { SelectField, SelectOpts } from 'components/selects';
import { Controller, useForm } from 'react-hook-form';
import { supportItemSchema } from 'validations/FormValidation';
import { SupportItemDto } from 'api';
import { SelectTags } from 'components/selects/SelectTags';
import { AddTagForm } from './Tags/AddTagForm';
import { SupportItemType, SupportItemTypes } from 'types/api';
import { supportCategories } from 'modules/Support';
import { McButton } from 'components/mc';
import { TooltipBubble } from 'components/TooltipBubble';
import { App } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getRequestError } from 'utils/errors';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import adminSupportService from 'services/admin/AdminSupportService';
import adminTagService from 'services/admin/AdminTagService';

interface AddSupportFormProps {
	hideForm: () => void;
	supportItem: SupportItemDto | 'new';
}

interface FormValues {
	question: string;
	answer: string;
	tags: Array<Number>;
	itemType: SupportItemType | undefined;
}

export const SupportItemForm: React.FC<AddSupportFormProps> = ({
	hideForm,
	supportItem,
}) => {
	const isNew = supportItem === 'new';

	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(supportItemSchema),
		defaultValues: {
			answer: '',
			question: '',
			tags: [],
			itemType: undefined,
		},
	});

	const [itemType, setItemType] = useState<SupportItemType | undefined>(
		isNew ? SupportItemTypes.QA : undefined
	);
	const [, setCompressedFile] = useState<Blob | undefined>();
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const [fileUploadName, setFileUploadName] = useState<string>();
	const [fileUpload, setFileUpload] = useState<Blob>();
	const [addTagModal, setAddTagModal] = useState(false);
	const [tags, setTags] = useState<string[]>([]);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'support');

	useEffect(() => {
		if (supportItem && !isNew) {
			setItemType(supportItem.itemType);
			setTags((supportItem?.tagIds ?? []).map((v: number) => '' + v));
			setValue('itemType', supportItem.itemType);
			setValue('question', supportItem.question);
			setValue('answer', supportItem.answer);
			setValue('tags', supportItem.tagIds);
		} else {
			setItemType(SupportItemTypes.QA);
			setTags([]);
			setValue('itemType', undefined);
			setValue('question', '');
			setValue('answer', '');
			setValue('tags', []);
		}
	}, [supportItem, setValue, isNew]);

	const category = useMemo(
		() => supportCategories.find((c) => c.itemType === itemType),
		[itemType]
	);

	const registerNewTags = async (tagItems: string[]) => {
		return Promise.all(
			tagItems.map((t) =>
				isNaN(Number(t))
					? adminTagService.createTag({ name: t }).then((tag) => tag.data.id)
					: Promise.resolve(Number(t))
			)
		);
	};

	const { mutate: addSupportItem, isPending: isPendingAdd } = useMutation({
		mutationFn: (supportItemDto: SupportItemDto) =>
			adminSupportService.createSupportItemForm(
				!!fileUpload,
				supportItemDto,
				selectedImage,
				fileUpload
			),
		onSuccess: () => {
			notification.success(saveSuccessNotification());
			queryClient.invalidateQueries({ queryKey: ['supportItems'] });
			hideForm();
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target === 'question'
			)
				setFormError(errorDto.target, {
					type: 'custom',
					message: 'The question/title already exists',
				});
			notification.error(
				mcErrorNotification('Error', error, 'create', 'support item')
			);
		},
	});

	const { mutate: deleteSupportItemImage } = useMutation({
		mutationFn: (id: number) =>
			adminSupportService.deleteSupportItemBasicPicture(id),
		onSuccess: () => setIsImageDeleted(false),
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'support item image')
			),
	});

	const { mutate: updateSupportItem, isPending: isPendingUpdate } = useMutation(
		{
			mutationFn: ({
				id,
				supportItemDto,
			}: {
				id: number;
				supportItemDto: SupportItemDto;
			}) =>
				adminSupportService.updateSupportItemForm(
					id,
					supportItemDto,
					selectedImage,
					fileUpload,
					!!fileUpload
				),
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['supportItems'] });
				hideForm();
			},
			onError: (error: unknown) => {
				const errorDto = getRequestError(error);
				if (
					errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
					errorDto.target === 'question'
				)
					setFormError(errorDto.target, {
						type: 'custom',
						message: 'The question/title already exists',
					});
				notification.error(
					mcErrorNotification('Error', error, 'update', 'support item')
				);
			},
		}
	);

	const isPending = isPendingAdd || isPendingUpdate;

	const addSupportItemHandler = async (data: FormValues) => {
		if (!isNew) return;

		const tagIds = await registerNewTags(tags);

		const supportItemDto: SupportItemDto = {
			id: 0, // TODO: This value isn't used during the creation. Should probably be reworked!
			question: data.question,
			answer: data.answer,
			itemType: data.itemType!,
			tagIds,
		};

		addSupportItem(supportItemDto);
	};

	const updateSupportItemHandler = async (data: FormValues) => {
		if (isNew) return;
		const tagIds = await registerNewTags(tags);
		const supportItemDto: SupportItemDto = {
			id: supportItem.id,
			question: data.question,
			answer: data.answer,
			itemType: data.itemType!,
			tagIds,
		};

		updateSupportItem({ id: supportItem.id, supportItemDto: supportItemDto });

		if (isImageDeleted) deleteSupportItemImage(supportItem.id);
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		hideForm();
	};

	return (
		<>
			<form
				className={isNew ? style.addFormWrapper : style.editFormWrapper}
				onSubmit={handleSubmit((data) => {
					isNew ? addSupportItemHandler(data) : updateSupportItemHandler(data);
				})}
			>
				<div className={style.coverImgContainer}>
					<PictureField
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						setCompressedFile={setCompressedFile}
						needToCompress={false}
						existingImagePath={isNew ? '' : supportItem?.fullSizeImagePath}
						isImageDeleted={isImageDeleted}
						setIsImageDeleted={setIsImageDeleted}
						isAvatar={false}
					/>
					<div className={style.infoTextWrapper}>
						<TooltipBubble
							position="right"
							tooltiptext="Upload an image of proportion W/H 3.05, preferably of horisontal width > 1000 pixels"
						/>
					</div>
				</div>
				<div className={style.uploadImgContainer}>
					<UploadFileField
						text={'Upload Attachment'}
						fileUploadName={fileUploadName}
						setFileUploadName={setFileUploadName}
						setFileUpload={setFileUpload}
						showLoader={isPending}
					/>
				</div>
				<div>
					<Controller
						name="itemType"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<SelectField
								value={itemType}
								options={
									[
										{ label: 'MANUAL', value: SupportItemTypes.MANUAL },
										{ label: 'QA', value: SupportItemTypes.QA },
										{ label: 'TUTORIAL', value: SupportItemTypes.TUTORIAL },
										{ label: 'ADMIN', value: SupportItemTypes.ADMIN },
									] as SelectOpts<SupportItemType | undefined>
								}
								label={'Item Type'}
								setSelectedField={(it) => {
									setItemType(it);
									setValue('itemType', it, {
										shouldDirty: true,
										shouldTouch: true,
										shouldValidate: true,
									});
								}}
								error={error}
							/>
						)}
					/>
					<div>
						<Controller
							name="question"
							control={control}
							render={({ field, fieldState: { error } }) => (
								<InputField
									placeholder={
										category?.titleField.placeholder ?? 'Enter a title'
									}
									{...field}
									label={category?.titleField.label ?? 'Question'}
									error={!!errors.question?.message || !!error}
									errorMessage={errors.question?.message ?? error?.message}
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							name="answer"
							control={control}
							render={({ field, fieldState: { error } }) => (
								<InputTextarea
									{...field}
									placeholder={
										category?.descriptionField.placeholder ??
										'Enter a description'
									}
									label={category?.descriptionField.label ?? 'Answer'}
									error={!!error}
									errorMessage={error?.message}
									maxCharacterLength={10000}
								/>
							)}
						/>
					</div>

					<SelectTags
						label={'Select tags'}
						value={tags}
						onChange={(tags) => setTags(tags)}
					/>
				</div>
				<div className={style.buttonsWrapper}>
					<McButton onClick={cancel}>Cancel</McButton>
					<McButton primary type="submit" disabled={!canEdit}>
						Save
					</McButton>
				</div>
			</form>
			{addTagModal && <AddTagForm setShowAddTagsWindow={setAddTagModal} />}
			{isPending && <DisabledScreen />}
		</>
	);
};
