import { Menu, Layout } from 'antd';
import type { MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import style from 'assets/styles/supportSidebar.module.scss';
import {
	SidebarOpenCollapseIcon,
	SidebarCollapseIcon,
	EnginesIcon,
} from 'assets/icons/svg';
import { SupportCategory, supportCategories } from './categories';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasReadPermission } from 'utils/permissions';
import TemaConnectLink from 'components/TemaConnectLink';

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
	backgroundColor: 'transparent',
	marginLeft: '0px',
};

type MenuItemType = Required<MenuProps>['items'][number];

const getMenuItem = (
	key: string,
	label: React.ReactNode,
	icon?: React.ReactNode,
	type?: 'group' | 'divider'
): MenuItemType => {
	if (type) {
		return { key, label, type, children: type === 'group' ? [] : undefined };
	}
	return { key, label, icon };
};

interface Props {
	selected: SupportCategory;
}

const SupportSidebar: React.FC<Props> = (props) => {
	const [collapsed, setCollapsed] = useState(window.innerWidth < 1024);
	const { isAdmin, permissions } = useAppSelector(authState);

	const canReadInternal = hasReadPermission(permissions, 'internal');

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const handleResize = () => {
			setCollapsed(window.innerWidth < 1024);
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const filteredSupportCategories = supportCategories.filter(
		(cat) => cat.key !== 'admin'
	);

	const menuItems: MenuItemType[] = [
		...filteredSupportCategories.map((cat) =>
			getMenuItem(
				cat.key,
				<TemaConnectLink to={`/support/${cat.key}`}>
					{cat.label}
				</TemaConnectLink>,
				<Icon component={cat.icon} />
			)
		),

		getMenuItem(
			'release-notes',
			<TemaConnectLink to="/support/release-notes">
				Release Notes
			</TemaConnectLink>,
			<Icon component={EnginesIcon} />
		),

		...(isAdmin || canReadInternal
			? [
					{
						key: 'admin-notes-group',
						label: <span className={style.menuCategoryTitle}>Admin Notes</span>,
						type: 'group' as const,
						children: supportCategories
							.filter((cat) => cat.key === 'admin')
							.map((cat) =>
								getMenuItem(
									cat.key,
									<TemaConnectLink to={`/support/${cat.key}`}>
										General
									</TemaConnectLink>,
									<Icon component={cat.icon} />
								)
							),
					},
			  ]
			: []),
	];

	return (
		<Sider
			theme="dark"
			breakpoint="xxl"
			collapsedWidth="78"
			width={242}
			className={style.supportSidebar}
			style={siderStyle}
			collapsed={collapsed}
		>
			<div
				onClick={toggleCollapsed}
				className={style.supportSidebarToggleButton}
			>
				<Icon
					component={collapsed ? SidebarOpenCollapseIcon : SidebarCollapseIcon}
				/>
			</div>
			<h4 className={style.menuCategoryTitle}>Categories</h4>
			<Menu
				theme="dark"
				mode="vertical"
				selectedKeys={[props.selected]}
				items={menuItems}
			/>
		</Sider>
	);
};

export default SupportSidebar;
