import { ReactNode, useEffect, useMemo } from 'react';
import style from 'assets/styles/manageElements.module.scss';
import { Cond } from 'utils/Cond';
import { McButton } from 'components/mc';
import {
	QueryFunction,
	UndefinedInitialDataOptions,
	useInfiniteQuery,
	useQuery,
} from '@tanstack/react-query';
import { AddButton } from 'components/buttons';
import { AlertCircle } from 'assets/icons/AlertCircle';
import { App, Modal, ModalFuncProps, ModalProps, Spin } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';

export const modalFormProps: ModalFuncProps & ModalProps = {
	footer: null,
	centered: true,
	closable: false,
	maskClosable: false,
	width: '60rem',
	styles: {
		body: { padding: 0, margin: 0 },
		content: { padding: 0, margin: 0, border: 'none' },
		header: { padding: 0, margin: 0, border: 'none' },
	},
	destroyOnClose: true,
} as ModalFuncProps & ModalProps;

interface Props<T> {
	entityName: string;
	filters?: ReactNode;
	canAddNewItem: boolean;
	queryKey: any[];
	queryFn: QueryFunction<any, any[], number>;
	renderItemsList: (items: T[]) => ReactNode;
	renderItemAddForm: () => ReactNode;
	invalidateEntityQuery: () => void;
	showForm: boolean;
	toggleShowForm: () => void;
	areFiltersActive: boolean;
	singleItemQueryOptions: UndefinedInitialDataOptions<unknown, unknown, T>;
	modalAddForm?: boolean;
}

const ManageEntities = <T,>({
	entityName,
	filters,
	canAddNewItem,
	queryFn,
	queryKey,
	renderItemsList,
	renderItemAddForm,
	showForm,
	toggleShowForm,
	areFiltersActive,
	singleItemQueryOptions,
	modalAddForm,
}: Props<T>) => {
	const { notification } = App.useApp();

	const {
		data: entityPages,
		isLoading: isLoadingPaginated,
		//isFetching: isFetchingPaginated,
		hasNextPage,
		fetchNextPage,
		error: fetchEntitiesError,
	} = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const {
		data: singleNewsItem,
		isLoading: isLoadingSingleItem,
		//isFetching: isFetchingSingleItem,
		error: fetchSingleItemError,
	} = useQuery(singleItemQueryOptions);

	useEffect(() => {
		if (!fetchEntitiesError) return;
		notification.error(
			mcErrorNotification('Error', fetchEntitiesError, 'fetch', entityName)
		);
	}, [entityName, fetchEntitiesError, notification]);

	useEffect(() => {
		if (!fetchSingleItemError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				fetchSingleItemError,
				'fetch',
				`requested ${entityName} item`
			)
		);
	}, [entityName, fetchSingleItemError, notification]);

	const entityList: T[] = useMemo(
		() =>
			!entityPages
				? []
				: entityPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as T[]
				  ),
		[entityPages]
	);

	const totalEntityNumber: number =
		!!entityPages && entityPages.pages.length > 0
			? entityPages.pages[0].allElements
			: 0;

	const loading = isLoadingSingleItem || isLoadingPaginated;

	return (
		<div className={style.containerManageNews}>
			<h1 className={style.header}>Manage {entityName}</h1>
			{singleNewsItem ? (
				renderItemsList([singleNewsItem])
			) : (
				<>
					{entityList.length === 0 && !areFiltersActive && !loading ? (
						<>
							<div
								className={style.noElementsInfo}
								style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
							>
								There are no {entityName} to show here
								<AlertCircle />
							</div>
							<AddButton onClick={toggleShowForm} disabled={!canAddNewItem} />
						</>
					) : (
						<>
							<div className={style.amountInfo}>
								{`Displaying ${entityList.length} out of ${totalEntityNumber} ${entityName} items`}
							</div>
							<div className={style.searchAddFields}>
								{filters}
								<AddButton onClick={toggleShowForm} disabled={!canAddNewItem} />
							</div>
						</>
					)}
					{!!modalAddForm ? (
						<Modal {...modalFormProps} open={showForm}>
							{renderItemAddForm()}
						</Modal>
					) : showForm ? (
						<div style={{ marginTop: '2rem' }}>{renderItemAddForm()}</div>
					) : (
						<></>
					)}
					<Spin spinning={loading}>
						<div
							style={{
								marginTop: '2rem', // TODO: add scss class for this!
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								gap: '1.5rem',
								justifyContent: 'space-evenly',
							}}
						>
							{renderItemsList(entityList)}
						</div>
					</Spin>
					<div>
						<Cond if={hasNextPage}>
							<div
								className={style.paginationContainer}
								onClick={() => {
									fetchNextPage();
								}}
							>
								<McButton disabled={!hasNextPage || loading}>
									View more
								</McButton>
							</div>
						</Cond>
					</div>
				</>
			)}
		</div>
	);
};

export default ManageEntities;
