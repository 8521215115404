import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadIcon } from 'assets/icons/svg';
import style from 'assets/styles/templateCard.module.scss';
import { getPublicImageURL } from 'services/ApiService';
import { McButton } from 'components/mc';
import { AppIcon } from 'assets/icons/apps';
import { ipc, isDesktop, useDesktopContext } from 'desktop';
import appReleasesService from 'services/AppReleasesService';
import applicationsService from 'services/ApplicationsService';
import { App } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';
import defaultImage from 'assets/images/top.jpg';
import adminTemplatesService, {
	adminBlobTemplatesService,
} from 'services/admin/AdminTemplatesService';
import { useMutation, useQuery } from '@tanstack/react-query';

interface CardProps {
	template: Template;
}

export const TemplateCard: FC<CardProps> = ({ template }) => {
	let navigate = useNavigate();
	const [applicationIcon, setApplicationIcon] = useState<string>();
	const { reloadUserTemplates } = useDesktopContext();
	const { notification } = App.useApp();

	const handleClickToOpenTemplate = (id: number) => {
		// TODO: add prefetch when hover!
		navigate('/templates/' + id);
	};

	const { data: templateAttachmentFileName } = useQuery({
		queryKey: ['templates', template.id, 'file', 'name'],
		queryFn: () =>
			adminTemplatesService
				.getTemplateFileName(template.id)
				.then((res) => res.data),
		enabled: !!template.downloadLink,
	});

	const { mutateAsync: downloadTemplateItem } = useMutation({
		mutationFn: ({
			templateId,
			fileName,
		}: {
			templateId: number;
			fileName: string;
		}) =>
			adminBlobTemplatesService
				.getTemplatesFile(templateId)
				.then((res) => res.data),
		onSuccess: (dataFile, { fileName }) => {
			let data = new Blob([dataFile]);
			let requestURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement('a');
			tempLink.href = requestURL;
			tempLink.setAttribute('download', fileName);
			tempLink.click();
		},

		onError: (err) => {
			notification.error(
				mcErrorNotification('Error', err, 'download', 'support item')
			);
		},
	});

	const downloadFromDesktop = async () => {
		if (isDesktop && template.id) {
			const promise = adminTemplatesService.getTemplatesFile(template.id, {
				responseType: 'arraybuffer',
			});

			await ipc.downloadFileToDesktop(
				promise,
				'Template',
				['tet'],
				'template.tet'
			);
			reloadUserTemplates();
		}
	};

	// TODO: fix this to use tanstack query instead so we can cache the result
	// Fetch application icon
	useEffect(() => {
		if (template.applicationReleaseId)
			appReleasesService
				.getApplicationReleaseById(template.applicationReleaseId)
				.then((res) =>
					applicationsService.getApplication(res.data.applicationId!)
				)
				.then((res) => setApplicationIcon(res.data.iconImagePath ?? undefined))
				.catch((err) => {
					console.error(
						'Failed to fetch corresponding application icon for the provided template!'
					);
					setApplicationIcon(undefined);
				});
	}, [template]);

	return (
		<div className={style.container}>
			<div
				className={style.imgContainer}
				onClick={() => {
					navigate('/templates/' + template.id);
				}}
			>
				<img
					alt={template.title || 'Template thumbnail'}
					src={
						template.thumbnailImagePath
							? getPublicImageURL(template.thumbnailImagePath)
							: defaultImage
					}
					onError={(e) => {
						e.currentTarget.src = defaultImage;
					}}
				/>
			</div>
			<div className={style.descriptionContainer}>
				<div className={style.iconContainer}>
					{applicationIcon ? (
						<img
							alt=""
							src={getPublicImageURL(applicationIcon)}
							width={24}
							height={24}
						/>
					) : (
						<AppIcon app="" size={24} />
					)}
					<h4
						className={style.title}
						onClick={() => {
							handleClickToOpenTemplate(template.id);
						}}
					>
						{template.title}
					</h4>
				</div>
				<p className={style.description}>{template.description}</p>
			</div>
			<div className={style.btnContainer}>
				<McButton
					onClick={() => {
						handleClickToOpenTemplate(template.id);
					}}
				>
					More info
				</McButton>
				{isDesktop ? (
					<div onClick={downloadFromDesktop}>
						<DownloadIcon />
					</div>
				) : (
					<div
						onClick={async (e) => {
							e.preventDefault();
							e.stopPropagation();
							if (!!template.downloadLink && !!templateAttachmentFileName) {
								await downloadTemplateItem({
									templateId: template.id,
									fileName: templateAttachmentFileName,
								});
							}
						}}
					>
						<DownloadIcon />
					</div>
				)}
			</div>
		</div>
	);
};
