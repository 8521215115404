import { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { transformLinkTarget } from 'utils/urls';
import { extractYoutubeId } from 'utils/youtube';
import './styles/markdown.scss';
import DOMPurify from 'dompurify';
import { useQuery } from '@tanstack/react-query';
import imageBankService from 'services/UtilityService/ImageBankService';

interface Props {
	content: string | undefined;
}

const YoutubeEmbed: FC<{ url: string }> = ({ url }) => {
	const videoId = extractYoutubeId(url);

	if (!videoId)
		return (
			<a href={url} target={transformLinkTarget(url)}>
				{url}
			</a>
		);

	return (
		<div className="youtube-embed">
			<iframe
				width="100%"
				height="315"
				src={`https://www.youtube.com/embed/${videoId}`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	);
};

const extractImageNamesFromMarkdown = (markdownText: string) => {
	// Regular expression to match markdown image syntax: ![alt text](url)
	const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;

	const imageNames = [];
	let match;

	// Find all image URLs in the markdown text
	while ((match = imageRegex.exec(markdownText)) !== null) {
		const imageName = match[2];
		if (imageName && !imageName.startsWith('http')) {
			imageNames.push(imageName);
		}
	}

	return imageNames;
};

export const McMarkdown: FC<Props> = (props) => {
	const imageList: string[] = useMemo(
		() => extractImageNamesFromMarkdown(props.content ?? ''),
		[props.content]
	);

	const { data: imageUrls } = useQuery({
		queryKey: ['images', imageList],
		queryFn: () =>
			imageBankService.getImageInfoList(imageList).then((res) => res.data),
		enabled: !!imageList && imageList.length > 0,
		initialData: {} as { [key: string]: string },
	});

	const markdown = useMemo(
		() =>
			(props.content ?? '').replaceAll(
				/!\[([^\]]*)\]\(([^)]+)\)/g,
				(match, altText, url) => {
					if (!url.includes('https://') && imageUrls[url] !== undefined) {
						return `![${altText}](${imageUrls[url]})`;
					}
					return match; // Leave other URLs unchanged
				}
			),
		[imageUrls, props.content]
	);

	const sanitizedMarkdown = DOMPurify.sanitize(markdown ?? '');

	return (
		<div className="mc-markdown">
			<ReactMarkdown
				linkTarget={transformLinkTarget}
				components={{
					a: ({ node, href, children }) => {
						if (
							href &&
							(href.includes('youtube.com') || href.includes('youtu.be'))
						) {
							return <YoutubeEmbed url={href} />;
						}
						return (
							<a href={href} target={transformLinkTarget(href)}>
								{children}
							</a>
						);
					},
				}}
			>
				{sanitizedMarkdown ?? ''}
			</ReactMarkdown>
		</div>
	);
};
