import { useState, useEffect, FC, useMemo } from 'react';
import { Section } from 'components/Section';
import { App, Spin, Tooltip } from 'antd';
import { ipc, isDesktop } from 'desktop';
import style from 'assets/styles/homePage.module.scss';
import { McButton, McMoreMenu } from 'components/mc';
import { localApplications as allLocalApplications } from 'desktop/LocalApplications';
import { useQuery } from '@tanstack/react-query';
import enginesService from 'services/EnginesService';
import { mcErrorNotification, warningNotification } from 'utils/Notifications';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import applicationsService from 'services/ApplicationsService';
import { invoke } from '@tauri-apps/api';
import { CMLicense } from 'types/codemeter';
import { EngineOption } from 'components/selects';
import { parseISO, isAfter } from 'date-fns';
import ApplicationThinCard from 'components/cards/ApplicationThinCard';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import TemaConnectLink from 'components/TemaConnectLink';

export type LicenseDesktopStatus = 'Unavailable' | 'Expired' | 'Available';

export const IncludedAppsSection: FC<{
	selectedEngine?: EngineOption | undefined;
}> = ({ selectedEngine }) => {
	const { isOnline, isGuest } = useAppSelector(authState);
	const onlineEngineAvailable: boolean =
		isOnline &&
		!!selectedEngine &&
		!!selectedEngine.online &&
		!!selectedEngine.online.engineId &&
		!isGuest;

	const {
		data: engineApplications,
		error: engineApplicationsError,
		isLoading: isLoadingEngineApplications,
	} = useQuery({
		queryKey: ['engines', selectedEngine?.online?.engineId, 'applications'],
		queryFn: () =>
			enginesService
				.getApplicationsByEngineId(selectedEngine?.online?.engineId!)
				.then((res) => res.data),
		initialData: [],
		enabled: onlineEngineAvailable,
	});

	const {
		data: engineApplicationReleases,
		isLoading: isLoadingEngineApplicationReleases,
		error: engineApplicationReleasesError,
	} = useQuery({
		queryKey: [
			'engines',
			selectedEngine?.online?.engineId,
			'applications',
			'releases',
		],
		queryFn: () =>
			enginesService
				.getApplicationReleasesByEngineId(selectedEngine?.online?.engineId!)
				.then((res) => res.data),
		initialData: [],
		enabled: onlineEngineAvailable,
	});

	const applicationCombination: {
		application: ApplicationDto;
		applicationRelease: ApplicationReleaseDto;
	}[] = useMemo(() => {
		const combinationResult: {
			application: ApplicationDto;
			applicationRelease: ApplicationReleaseDto;
		}[] = [];

		engineApplicationReleases?.forEach((appRelease) => {
			if (!!appRelease.applicationId) {
				const app = engineApplications.find(
					(app) => appRelease.applicationId === app.id
				);

				if (!!app)
					combinationResult.push({
						application: app,
						applicationRelease: appRelease,
					});
			}
		});

		return combinationResult;
	}, [engineApplicationReleases, engineApplications]);

	const {
		data: userOwnedApplications,
		error: userOwnedApplicationsError,
		isLoading: isLoadingOwnedApplications,
	} = useQuery({
		queryKey: ['applications', 'owned'],
		queryFn: () =>
			applicationsService.getOwnedApplications().then((res) => res.data),
		initialData: [] as ApplicationDto[],
		enabled: isOnline && !isGuest,
	});

	const { notification } = App.useApp();

	const title =
		!!engineApplications.length ||
		(!!selectedEngine &&
			!!selectedEngine.installed &&
			!!selectedEngine.installed.localApplications.length)
			? 'Included applications'
			: 'You have not included any Applications';

	const [error, setError] = useState<{ error: string } | null>(null);

	useEffect(() => {
		if (!error) return;
		notification.error({
			message: 'Failed to open application',
			description: error.error,
			placement: 'top',
			onClose: () => setError(null),
		});
	}, [error, notification]);

	useEffect(() => {
		if (!engineApplicationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				engineApplicationsError,
				'fetch',
				'applications'
			)
		);
	}, [engineApplicationsError, notification]);

	useEffect(() => {
		if (!userOwnedApplicationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				userOwnedApplicationsError,
				'fetch',
				'user owned applications'
			)
		);
	}, [notification, userOwnedApplicationsError]);

	useEffect(() => {
		if (!engineApplicationReleasesError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				engineApplicationReleasesError,
				'fetch',
				'application releases'
			)
		);
	}, [engineApplicationReleasesError, notification]);

	const { data: localProductCodes, error: getCodemeterLicensesError } =
		useQuery({
			queryKey: ['local', 'products', 'codes'],
			queryFn: () =>
				invoke('get_codemeter_licenses', {
					firmCode: 6000157,
				}).then((res) => {
					var licenses = res as CMLicense[];
					if (!Array.isArray(licenses)) {
						licenses = [] as CMLicense[];
					}
					const now: Date = new Date();
					const expired: Number[] = [];
					const productCodes = licenses
						.filter((l) => {
							let expirationFilter: boolean = true;
							if (
								l.expiration_time !== undefined &&
								l.expiration_time !== null
							) {
								const expiration: Date = parseISO(l.expiration_time);
								if (!isAfter(expiration, now)) {
									expired.push(l.product.code);
								}
								expirationFilter = isAfter(expiration, now);
							}
							return (
								l.firm.code === 6000157 &&
								l.license_quantity > 0 &&
								expirationFilter
							);
						})
						.map((l) => l.product.code);

					return { available: productCodes, expired: expired };
				}),
			initialData: { expired: [], available: [] } as {
				expired: Number[];
				available: Number[];
			},
			enabled: isDesktop,
		});

	useEffect(() => {
		if (!getCodemeterLicensesError) return;
		notification.warning(
			warningNotification('Could not read codemeter licenses!')
		);
	}, [getCodemeterLicensesError, notification]);

	const launchApplication = (code: string) => {
		if (!selectedEngine || !selectedEngine.installed) return;
		ipc.launchApplication(selectedEngine.installed.name, code).then(
			(r) => setError(null),
			(e) => {
				console.error('Application failed to launch: %o', e);
				setError(e);
			}
		);
	};

	// Keys to the object are the app codes and returns true if licensed, otherwise false/undefined
	const isApplicationLicensed: { [appCode: string]: boolean } = useMemo(() => {
		const lookupMap: { [appCode: string]: boolean } = {};

		if (isDesktop) {
			// While on desktop, activated licenses are determined strictly by codemeter
			const appCodes: string[] = Object.keys(allLocalApplications);
			appCodes?.forEach(
				(appCode) =>
					(lookupMap[appCode] = allLocalApplications[
						appCode
					].requiredModuleProductCodes.every((prodCode) =>
						localProductCodes.available.includes(prodCode)
					))
			);
		} else {
			// While on the webapp, licenses are only determined by what is activated in motion cloud
			userOwnedApplications?.forEach((app) => {
				if (!!app.code) lookupMap[app.code] = true;
			});
		}

		return lookupMap;
	}, [localProductCodes.available, userOwnedApplications]);

	const hasApplicationExpired: { [appCode: string]: boolean } = useMemo(() => {
		const lookupMap: { [appCode: string]: boolean } = {};

		if (isDesktop) {
			const appCodes: string[] = Object.keys(allLocalApplications);
			appCodes?.forEach(
				(appCode) =>
					(lookupMap[appCode] =
						allLocalApplications[appCode].requiredModuleProductCodes.every(
							(prodCode) => localProductCodes.expired.includes(prodCode)
						) && !isApplicationLicensed[appCode])
			);
		}
		return lookupMap;
	}, [isApplicationLicensed, localProductCodes.expired]);

	const loading =
		isLoadingEngineApplications ||
		isLoadingOwnedApplications ||
		isLoadingEngineApplicationReleases;

	return (
		<Section title={title}>
			<Spin spinning={loading} size="large">
				<div className={style.includedAppsContainer}>
					{!!selectedEngine &&
					!!selectedEngine.installed &&
					selectedEngine.installed.localApplications.length > 0
						? selectedEngine.installed.localApplications.map(
								(localApplication) => (
									<div key={selectedEngine.version + localApplication.name}>
										<ApplicationThinCard
											hasLicense={isApplicationLicensed[localApplication.code]}
											hasLicenseExpired={
												!isApplicationLicensed[localApplication.code] &&
												hasApplicationExpired[localApplication.code]
											}
											title={localApplication.name}
											icon={<localApplication.icon size={24} />}
											buttons={
												<Tooltip
													title={`Open application ${localApplication.name} in ${selectedEngine.installed?.name}`}
												>
													<McButton
														disabled={
															!selectedEngine?.installed ||
															!isApplicationLicensed[localApplication.code] ||
															isLoadingEngineApplications
														}
														onClick={() =>
															launchApplication(localApplication.code)
														}
													>
														Open
													</McButton>
												</Tooltip>
											}
										/>
									</div>
								)
						  )
						: applicationCombination.map((appCombo) => (
								<div
									key={
										appCombo.application.id +
										'-' +
										appCombo.applicationRelease.id
									}
								>
									<ApplicationThinCard
										hasLicense={
											isApplicationLicensed[appCombo.application.code ?? '']
										}
										hasLicenseExpired={
											!isApplicationLicensed[appCombo.application.code ?? ''] &&
											hasApplicationExpired[appCombo.application.code ?? '']
										}
										title={appCombo.application.name}
										iconPath={appCombo.application.iconImagePath ?? ''}
										buttons={
											<>
												{isDesktop && (
													<McButton
														disabled={
															!selectedEngine?.installed ||
															!isApplicationLicensed[
																appCombo.application.code ?? ''
															]
														}
														onClick={() =>
															launchApplication(appCombo.application.code ?? '')
														}
													>
														Open
													</McButton>
												)}
												<McMoreMenu placement="bottomRight">
													<TemaConnectLink
														to={`/apps/${appCombo.applicationRelease.id}`}
														className="detailPage"
													>
														Show detail page
													</TemaConnectLink>
												</McMoreMenu>
											</>
										}
									/>
								</div>
						  ))}
				</div>
			</Spin>
		</Section>
	);
};
