import { useState, useEffect, FC, useMemo } from 'react';
import { ManageOneEngine } from 'modules/ManageEngines/ManageOneEngine';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import style from 'assets/styles/manageElements.module.scss';
import { FilterReleaseYear } from 'components/FilterReleaseYear';
import { useDebounce } from 'hooks/useDebounce';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { FilterField } from 'components/fields';
import {
	StatusSelection,
	statusSelectionLabels,
	statusSelectionValues,
} from 'types/status-selection';
import { McButton } from 'components/mc';
import { EditEngineForm } from 'modules/ManageEngines/EditEngineForm';
import { useInfiniteQuery } from '@tanstack/react-query';
import { EngineDto } from 'api';
import { mcErrorNotification } from 'utils/Notifications';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import adminEnginesService from 'services/admin/AdminEnginesService';

export const ManageEngines: FC = () => {
	const { notification } = App.useApp();
	const [selStatus, setSelStatus] = useState<StatusSelection>();
	const [showAddEngineForm, setShowAddEngineForm] = useState(false);
	const [selReleaseYear, setSelReleaseYear] = useState<number>();
	const [searchedValue, setSearchedValue] = useState('');
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'engines');

	const {
		data: enginePages,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
		error: fetchEnginesError,
	} = useInfiniteQuery({
		queryKey: [
			'engines',
			'infinite',
			debouncedSearchTerm,
			selReleaseYear,
			selStatus,
		],
		queryFn: ({ pageParam: pageNr }) =>
			adminEnginesService
				.getEngines(
					pageNr,
					PAGE_SIZE,
					debouncedSearchTerm,
					selReleaseYear,
					selStatus
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const engines: EngineDto[] = useMemo(
		() =>
			!enginePages
				? []
				: enginePages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as EngineDto[]
				  ),
		[enginePages]
	);

	const allEngines: number =
		!!enginePages && enginePages.pages.length > 0
			? enginePages.pages[0].allElements
			: 0;

	useEffect(() => {
		if (!fetchEnginesError) return;
		notification.error(
			mcErrorNotification('Error', fetchEnginesError, 'fetch', 'engines')
		);
	}, [fetchEnginesError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Engines</h1>
				{!isLoading &&
				!isFetching &&
				searchedValue === '' &&
				selStatus === undefined &&
				selReleaseYear === undefined &&
				allEngines === 0 ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							No Engines found
							<AlertCircle />
						</div>
						<AddButton
							onClick={() => setShowAddEngineForm((prev) => !prev)}
							disabled={!canEdit}
						/>
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${engines.length} out of ${allEngines} engines`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchedValue}
									onChange={(e) => setSearchedValue(e.target.value)}
								/>
							</div>
							<FilterReleaseYear
								label={'Release Year: '}
								setSelectedField={setSelReleaseYear}
							/>
							<div style={{ flex: '0 0 10.3125rem' }}>
								<FilterField
									label={'Status: '}
									selValue={selStatus}
									values={statusSelectionValues}
									labels={statusSelectionLabels}
									admin={true}
									setSelectedField={setSelStatus}
								/>
							</div>
							<AddButton
								onClick={() => setShowAddEngineForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
					</>
				)}

				<div style={{ marginTop: '2rem' }}>
					{showAddEngineForm && (
						<EditEngineForm
							setShowAddEngineForm={setShowAddEngineForm}
							engineWithFullSizeImg={'new'}
						/>
					)}
				</div>
				{allEngines !== 0 && <h3 className={style.listTitle}>Engine List</h3>}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading &&
							!isFetching &&
							allEngines === 0 &&
							(searchedValue !== '' ||
								selStatus !== undefined ||
								selReleaseYear !== undefined) && (
								<div className={style.noElementsInfo}>
									No engines found
									<AlertCircle />
								</div>
							)}
						{allEngines !== 0 && (
							<div className={style.itemsContainer}>
								{engines.map((engine) => {
									return (
										<div key={engine.id}>
											<ManageOneEngine
												engine={engine}
												setSelectedToUpdate={setSelectedToUpdate}
												selectedToUpdate={selectedToUpdate}
											/>
										</div>
									);
								})}
							</div>
						)}
					</Spin>
				</div>
				{allEngines !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => {
							fetchNextPage();
						}}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
