import { FC, useEffect, useState } from 'react';
import 'assets/styles/header.scss';
import { MenuOutlined } from '@ant-design/icons';
import {
	AvatarIcon,
	BellIcon,
	MotionLogoIcon,
	MotionLogoText,
	CrossedAvatarIcon,
} from 'assets/icons/svg';

import Icon from '@ant-design/icons';

import { Badge, MenuProps, Popover, Menu, App } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfilePopOver } from 'components/ProfilePopOver';
import { Notifications } from './Notifications';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { isDesktop } from 'desktop';
import { User, isUserDto } from 'types/user';
import { GlobalSearch } from './Search';
import { getPublicImageURL } from 'services/ApiService';
import notificationsService from 'services/NotificationsService';
import { mcErrorNotification } from 'utils/Notifications';
import { useQuery } from '@tanstack/react-query';
import UpdateBell from 'components/UpdateBell';

export const CustomHeader: FC = () => {
	const { isOnline, user } = useAppSelector(authState);
	const [visible, setVisible] = useState<boolean>(false);

	const { notification } = App.useApp();

	const navigate = useNavigate();
	const location = useLocation();
	const path = [
		'',
		'store',
		'admin',
		'templates',
		'licensing',
		'support',
	].includes(location.pathname.split('/')[1])
		? '/' + location.pathname.split('/')[1]
		: '/';

	const [current, setCurrent] = useState(path);

	useEffect(() => {
		setCurrent(path);
	}, [location.pathname, path]);

	const onClick: MenuProps['onClick'] = (e) => {
		setCurrent(e.key);
		navigate(current, { replace: true });
	};

	const { isGuest, isAdmin } = useAppSelector(authState);
	const showAdminMenu = !isDesktop && isAdmin;

	const headerMenuItems: MenuProps['items'] = [
		{
			label: 'Home',
			key: '/',
		},
		/*{
			label: 'Templates',
			key: '/templates',
		},*/
		{
			label: 'Store',
			key: '/store',
			disabled: isGuest,
		},
		{
			label: 'Licensing',
			key: '/licensing',
		},
		{
			label: 'Support',
			key: '/support',
			disabled: isGuest,
		},
		...(showAdminMenu
			? [
					{
						label: 'Admin',
						key: '/admin',
					},
			  ]
			: []),
	];

	const { data: unreadCount, error: fetchUnreadNotificationsCountError } =
		useQuery({
			queryKey: ['notifications', 'unread', 'count'],
			queryFn: () =>
				notificationsService
					.getUnreadNotificationsCounter()
					.then((res) => res.data),
			enabled: isOnline && !isGuest,
			initialData: 0,
		});

	useEffect(() => {
		if (!fetchUnreadNotificationsCountError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				fetchUnreadNotificationsCountError,
				'fetch',
				'unread notifications count'
			)
		);
	}, [fetchUnreadNotificationsCountError, notification]);

	return (
		<div className="header-content">
			<div
				className="logo-container"
				onClick={() => {
					navigate('/');
				}}
			>
				<MotionLogoIcon />
				{!isDesktop && <MotionLogoText />}
			</div>
			<div className="header-middle-section">
				<Menu
					theme="dark"
					mode="horizontal"
					defaultSelectedKeys={[location.pathname]}
					items={headerMenuItems}
					onClick={onClick}
					selectedKeys={[current]}
					overflowedIndicator={<MenuOutlined />}
					onSelect={({ key }) => {
						setCurrent(key);
						navigate(`../${key}`);
					}}
					className="headerMenu"
				/>
				<div className="empty-space"></div>
				<GlobalSearch />
			</div>
			<div className="profile-container">
				{isDesktop && <UpdateBell />}
				<Popover
					placement="bottomRight"
					content={<Notifications />}
					trigger="click"
					arrow={false}
					overlayClassName="customPopover-topPosition"
				>
					<div className="header-bellIcon">
						<Badge count={unreadCount} size="small" offset={[-5, 2]}>
							<Icon component={() => <BellIcon zoomResize />} />
						</Badge>
					</div>
				</Popover>
				<Popover
					placement="bottomLeft"
					content={<ProfilePopOver setVisible={setVisible} visible={visible} />}
					trigger="click"
					afterOpenChange={setVisible}
					arrow={false}
					overlayClassName="customPopover-topPosition"
				>
					<div className="header-avatar">
						<UserAvatar user={user} />
					</div>
				</Popover>
			</div>
		</div>
	);
};

const UserAvatar: FC<{ user: User }> = ({ user }) => {
	if (!isUserDto(user)) return <CrossedAvatarIcon />;
	if (!user.avatarPath) return <AvatarIcon />;

	return <img alt="User Avatar" src={getPublicImageURL(user.avatarPath)} />;
};
