import * as Sentry from '@sentry/react';

export const setupErrorHandling = () => {
	let tracePropagationTargets;
	let sentryEnvironment;

	switch (process.env.REACT_APP_CHANNEL) {
		case 'test':
			tracePropagationTargets = [
				/^https:\/\/test\.temaplatform\.com/,
				/^https:\/\/test\.temaplatform\.com\/api/,
			];
			sentryEnvironment = 'development';
			break;
		case 'dev':
			tracePropagationTargets = [
				/^https:\/\/dev\.temaplatform\.com/,
				/^https:\/\/dev\.temaplatform\.com\/api/,
			];
			sentryEnvironment = 'development';
			break;
		case 'prod':
			tracePropagationTargets = [
				/^https:\/\/temaplatform\.com/,
				/^https:\/\/temaplatform\.com\/api/,
			];
			sentryEnvironment = 'production';
			break;
		default:
			// If there is any other value, return it without initializing Sentry
			return;
	}

	// Initialize Sentry with environment-specific configuration
	Sentry.init({
		dsn: 'https://6f1a5edf7b35e6a6d88fd428bdafdecc@o4506030657372160.ingest.sentry.io/4506275676356608',
		environment: sentryEnvironment,
		tracePropagationTargets: tracePropagationTargets,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.feedbackIntegration({
				isNameRequired: true,
				isEmailRequired: true,
				colorScheme: 'dark',
				triggerLabel: 'Feedback',
				formTitle: 'Send Feedback',
				messagePlaceholder:
					'Describe your feedback, suggestion or the bug you want to report.',
				successMessageText: 'Thank you for your feedback!',
				submitButtonLabel: 'Submit',
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};
