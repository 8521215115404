import React, { ReactNode, useMemo, useState } from 'react';
import style from 'assets/styles/ManageEntityCard.module.scss';
import { EntityStatus } from 'api';
import defaultImage from 'assets/images/top.jpg';
import { getPublicImageURL } from 'services/ApiService';
import { useMutation } from '@tanstack/react-query';
import { App, Modal, Spin, Tooltip } from 'antd';
import {
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { McIconButton } from 'components/mc';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { capitalize } from 'utils';
import { modalFormProps } from './ManageEntities';

interface Grid {
	type: 'GRID';
	imageUrl?: string;
	title: string;
	content?: string;
	entityStatus: EntityStatus;
}

interface List {
	type: 'LIST';
	content: ReactNode;
}

interface Props {
	displayMode: Grid | List;
	buttons?: ReactNode;
	excludeDefaultButtons?: boolean;
	deleteFn: () => Promise<unknown>;
	invalidateQueryFn: () => void;
	canDelete?: boolean;
	canEdit?: boolean;
	editForm: ReactNode;
	open: boolean;
	setOpen: () => void;
	date?: string;
}

const ManageEntityCard = ({
	displayMode,
	buttons,
	deleteFn,
	canDelete,
	canEdit,
	excludeDefaultButtons,
	invalidateQueryFn,
	editForm,
	open,
	setOpen,
	date,
}: Props) => {
	const { notification } = App.useApp();
	const [deleteOpen, setDeleteOpen] = useState(false);
	const { mutate: deleteNewsItem, isPending: isPendingDelete } = useMutation({
		mutationFn: deleteFn,
		onMutate: () => {
			setDeleteOpen(false);
		},
		onSuccess: () => {
			notification.success(deleteSuccessNotification());
			invalidateQueryFn();
		},
		onError: (e: unknown) => {
			notification.error(mcErrorNotification('Error', e, 'delete', 'item'));
		},
	});

	const defaultButtons: ReactNode = useMemo(
		() =>
			!!excludeDefaultButtons ? (
				<></>
			) : (
				<>
					<Tooltip title={'Edit Item'}>
						<div className={style.button}>
							<McIconButton
								icon={<IconEdit />}
								disabled={!canEdit}
								onClick={() => {
									setOpen();
								}}
							/>
						</div>
					</Tooltip>
					<Tooltip title={'Delete Item'}>
						<div className={style.button}>
							<McIconButton
								icon={<IconTrash />}
								disabled={!canDelete}
								onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
									e.preventDefault();
									setDeleteOpen(true);
								}}
							/>
						</div>
					</Tooltip>
				</>
			),
		[canDelete, canEdit, excludeDefaultButtons, setOpen]
	);

	return (
		<>
			<Modal
				okType="danger"
				okText="Delete"
				title="Confirm Deletion"
				open={deleteOpen}
				onCancel={() => setDeleteOpen(false)}
				onOk={() => {
					deleteNewsItem();
				}}
				centered
				maskClosable
			>
				Are you sure you want to delete the item? This action is irreversible!
			</Modal>
			<div>
				<Spin spinning={isPendingDelete}>
					{displayMode.type === 'GRID' ? (
						<>
							<div className={style.gridCard}>
								<div>
									<img
										alt={displayMode.title}
										className={style.cardImage}
										src={
											displayMode.imageUrl
												? getPublicImageURL(displayMode.imageUrl)
												: defaultImage
										}
										onError={(e) => {
											e.currentTarget.src = defaultImage;
										}}
									/>
									<Tooltip title={displayMode.title}>
										<h3 className={style.title}>{displayMode.title}</h3>
									</Tooltip>
									<div className={style.description}>{displayMode.content}</div>
								</div>
								<div className={style.footer}>
									<div
										style={{ display: 'inline-flex' }}
										className={style.cardInfo}
									>
										<span>{capitalize(displayMode.entityStatus)}</span>
										<span style={{ color: 'var(--text-disabled)' }}>
											{!!date ? `(${date})` : ''}
										</span>
									</div>
									<div
										style={{
											display: 'inline-flex',
										}}
									>
										{buttons}
										{defaultButtons}
									</div>
								</div>
							</div>
							<Modal open={open} {...modalFormProps}>
								{editForm}
							</Modal>
						</>
					) : (
						<>
							{
								// TODO: fix this for the non grid variants!
								<>
									<div
										style={{ width: '100%' }}
										className={style.itemContainer}
									>
										{displayMode.content}
										{buttons}
									</div>
									{editForm}
								</>
							}
						</>
					)}
				</Spin>
			</div>
		</>
	);
};

export default ManageEntityCard;
