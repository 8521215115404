import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LinkInputIcon, StarIcon } from 'assets/icons/svg';
import style from 'assets/styles/editAddElementForm.module.scss';
import {
	InputDate,
	InputField,
	InputTextarea,
	PictureField,
} from 'components/fields';
import { SelectField } from 'components/selects';
import { useAppSelector } from 'hooks/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { newsSchema } from 'validations/FormValidation';
import { AddNewsDto, EntityStatus, NewsDto, NewsCategory } from 'api';
import {
	NewsCategories,
	EntityStatus as NewsStatus,
	NewsStatuses,
} from 'types/api';
import { NewsCategoryOptions } from 'types/news';
import { App } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';
import adminNewsService from 'services/admin/AdminNewsService';

interface EditNewsFormProps {
	hideForm: () => void;
	newsItemInput: NewsDto | 'new';
}

interface FormValues {
	title: string;
	description: string;
	link: string;
	date: string;
}

export const EditNewsForm: React.FC<EditNewsFormProps> = ({
	newsItemInput,
	hideForm,
}) => {
	const [newsItem] = useState<NewsDto | 'new'>(newsItemInput);
	const isNew = newsItem === 'new';

	const { formState, handleSubmit, control, setValue } = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(newsSchema),
		defaultValues: isNew
			? {
					link: '',
					description: '',
					title: '',
					date: '',
			  }
			: {
					link: newsItem.link ?? '',
					description: newsItem.description ?? '',
					title: newsItem.title ?? '',
					date: newsItem.date ?? '',
			  },
	});

	const { permissions } = useAppSelector(authState);
	const canEdit = hasWritePermission(permissions, 'news');
	const [status, setStatus] = useState<EntityStatus | undefined>(
		isNew ? undefined : newsItem.entityStatus
	);
	const [category, setCategory] = useState<NewsCategory>(
		isNew ? NewsCategories.NEWS : newsItem.category ?? NewsCategories.NEWS
	);
	const [featured, setFeatured] = useState(isNew ? false : newsItem.extraNew);
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [compressedFile, setCompressedFile] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (!!newsItem && !isNew) {
			setValue('title', newsItem.title ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue('link', newsItem.link ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue('description', newsItem.description ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setCategory(newsItem.category ?? NewsCategories.NEWS);
			setValue('date', newsItem.date ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setStatus(newsItem.entityStatus);
			setFeatured(newsItem.extraNew);
		}
	}, [isNew, newsItem, setValue]);

	const submitFormHandler: SubmitHandler<FormValues> = (data) => {
		isNew ? createNewsItemHandler(data) : updateNewsItemHandler(data);
	};

	const { mutate: createNewsItem, isPending: isPendingCreateNews } =
		useMutation({
			mutationFn: ({
				dto,
				image,
				compressedImage,
			}: {
				dto: AddNewsDto;
				image?: Blob;
				compressedImage?: Blob;
			}) =>
				adminNewsService
					.addNewItemForm(dto, image, compressedImage)
					.then((res) => res.data),
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['news'] });
				hideForm();
			},
			onError: (e: unknown) => {
				notification.error(
					mcErrorNotification('Error', e, 'save', 'news item')
				);
			},
		});

	const { mutate: updateNewsItem, isPending: isPendingUpdateNews } =
		useMutation({
			mutationFn: ({
				id,
				dto,
				deleteImage,
				image,
				compressedImage,
			}: {
				id: number;
				dto: NewsDto;
				deleteImage: boolean;
				image?: Blob;
				compressedImage?: Blob;
			}) =>
				adminNewsService
					.updateNewsItemForm(id, dto, image, compressedImage, deleteImage)
					.then((res) => res.data),
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['news'] });
				hideForm();
			},
			onError: (e: unknown) => {
				notification.error(
					mcErrorNotification('Error', e, 'update', 'news item')
				);
			},
		});

	const hasChanges: boolean = useMemo(() => {
		if (isNew) return true;
		const featuredChanged = featured !== newsItem.extraNew;
		const imgChanged = selectedImage instanceof File;
		const statusChanged = status !== newsItem.entityStatus;
		const categoryChanged = category !== newsItem.category;

		return (
			formState.isDirty ||
			featuredChanged ||
			imgChanged ||
			statusChanged ||
			categoryChanged ||
			isImageDeleted
		);
	}, [
		category,
		featured,
		formState.isDirty,
		isImageDeleted,
		isNew,
		newsItem,
		selectedImage,
		status,
	]);

	const createNewsItemHandler = (data: FormValues) => {
		const newsDto: AddNewsDto = {
			title: data.title.trim(),
			description: data.description.trim(),
			date: data.date,
			category: category,
			extraNew: featured,
			entityStatus: status,
		};

		createNewsItem({
			dto: newsDto,
			image: selectedImage,
			compressedImage: compressedFile,
		});
	};
	const updateNewsItemHandler = (data: FormValues) => {
		if (isNew) return;
		const titleChanged = data.title.trim() !== newsItem.title;
		const linkChanged = data.link.trim() !== newsItem.link;
		const descChanged = data.description.trim() !== newsItem.description;
		const imgChanged = selectedImage instanceof File;
		const extraChanged = featured !== newsItem.extraNew;
		const statusChanged = status !== newsItem.entityStatus;
		const categoryChanged = category !== newsItem.category;
		const dateChanged = data.date !== newsItem.date;

		const hasChanges =
			titleChanged ||
			descChanged ||
			imgChanged ||
			isImageDeleted ||
			extraChanged ||
			statusChanged ||
			linkChanged ||
			dateChanged ||
			categoryChanged;

		if (!hasChanges) return;

		const dto: NewsDto = {
			id: newsItem.id,
			version: newsItem.version,
			title: titleChanged ? data.title.trim() : undefined,
			description: descChanged ? data.description.trim() : undefined,
			extraNew: extraChanged ? featured : undefined,
			entityStatus: (statusChanged ? status : undefined) as NewsStatus,
			date: dateChanged ? data.date : undefined,
			link: linkChanged ? data.link.trim() : undefined,
			category: categoryChanged ? category : undefined,
		};

		updateNewsItem({
			id: newsItem.id,
			dto: dto,
			deleteImage: isImageDeleted,
			image: imgChanged ? selectedImage : undefined,
			compressedImage: imgChanged ? compressedFile : undefined,
		});
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		hideForm();
	};

	return (
		<form
			className={style.addFormWrapper}
			onSubmit={handleSubmit(submitFormHandler)}
		>
			<PictureField
				selectedImage={selectedImage}
				setSelectedImage={setSelectedImage}
				setCompressedFile={setCompressedFile}
				needToCompress={true}
				existingImagePath={isNew ? undefined : newsItem.fullSizeImagePath}
				isImageDeleted={isImageDeleted}
				setIsImageDeleted={setIsImageDeleted}
				isAvatar={false}
			/>
			<div className={style.editForm} style={{ marginTop: '1.5rem' }}>
				<div className={style.column}>
					<Controller
						name="title"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'News Title'}
								{...field}
								label={'Title'}
								error={!!formState.errors.title}
								errorMessage={formState.errors.title?.message}
							/>
						)}
					/>
					<SelectField
						value={category}
						options={NewsCategoryOptions}
						label={'Category'}
						setSelectedField={setCategory}
					/>
				</div>
				<div className={style.column}>
					<SelectField
						defaultValue={status}
						options={[
							{ label: 'Draft', value: NewsStatuses.DRAFT },
							{ label: 'Published', value: NewsStatuses.PUBLISHED },
						]}
						label={'Status'}
						setSelectedField={setStatus}
					/>
					<Controller
						name="date"
						control={control}
						render={({ field }) => (
							<InputDate
								label={'Date'}
								value={field.value}
								setDate={(date) =>
									setValue('date', date, {
										shouldDirty: true,
										shouldTouch: true,
										shouldValidate: true,
									})
								}
								ref={field.ref}
							/>
						)}
					></Controller>
				</div>
			</div>
			<Controller
				name="link"
				control={control}
				render={({ field }) => (
					<InputField
						label="Link"
						icon={<LinkInputIcon />}
						placeholder="https://example.com"
						{...field}
					/>
				)}
			/>
			<Controller
				name="description"
				control={control}
				render={({ field }) => (
					<InputTextarea
						{...field}
						placeholder={'Description'}
						label={'Description'}
						error={formState.errors.description === undefined ? false : true}
						errorMessage={formState.errors.description?.message}
						maxCharacterLength={10000}
					/>
				)}
			/>
			<div className={style.buttonsWrapper}>
				<div className={style.extraNewsField} style={{ flexGrow: '1' }}>
					<div className={style.extraNewsTitle}>Featured News:</div>
					<div
						className={featured ? style.extraNewsIcon : style.notExtraNewsIcon}
						onClick={() => {
							setFeatured((prev) => !prev);
						}}
						style={{ display: 'inline-flex' }}
					>
						<StarIcon />
					</div>
					{!isNew && (
						<div>
							<div style={{ color: 'var(--text-disabled)' }}>
								(eid: {newsItem.id}, ver: {newsItem.version})
							</div>
							{newsItemInput !== 'new' &&
								newsItem.version !== newsItemInput.version && (
									<div style={{ color: 'var(--add-red)' }}>
										outdated (new existing version:
										{` ${newsItemInput.version}`})
									</div>
								)}
						</div>
					)}
				</div>
				<McButton onClick={cancel}>Cancel</McButton>
				<McButton
					primary
					type="submit"
					disabled={
						!canEdit ||
						!hasChanges ||
						isPendingCreateNews ||
						isPendingUpdateNews
					}
				>
					{isNew ? 'Create' : 'Update'}
				</McButton>
			</div>
		</form>
	);
};
