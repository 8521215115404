import { useQuery } from '@tanstack/react-query';
import { checkUpdate } from '@tauri-apps/api/updater';
import { Tooltip } from 'antd';
import { isDesktop } from 'desktop';
import { useEffect } from 'react';
import { McButton } from './mc';
import { useAppDispatch } from 'hooks/hooks';
import { setUpdaterOpen } from 'store/slices/updater';
import style from 'assets/styles/updater.module.scss';

const UpdateBell = () => {
	const dispatch = useAppDispatch();

	const { data: update, error: checkUpdateError } = useQuery({
		queryKey: ['desktop', 'update'],
		queryFn: () => checkUpdate().then((res) => res),
		enabled: isDesktop,
		initialData: undefined,
		refetchInterval: 5 * 60 * 1000,
	});

	useEffect(() => {
		if (!checkUpdateError) return;
		console.log('No desktop updates found!');
	}, [checkUpdateError]);

	const isActive = !!update && update.shouldUpdate;

	return (
		<Tooltip
			title={
				isActive ? 'New desktop update available!' : 'No desktop updates found!'
			}
		>
			<McButton
				className={`${style.updateBell} ${
					isActive ? style.active : style.inactive
				}`}
				disabled={!isActive}
				onClick={() => {
					dispatch(setUpdaterOpen(true));
				}}
			>
				Update
			</McButton>
		</Tooltip>
	);
};

export default UpdateBell;
